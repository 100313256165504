import React, { Component } from 'react';

import ConsentForm from '../entry/ConsentForm';

export default class EntryStep3 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
  }

  render() {
    const { handleNextStep, copy, uploadMode, cachedData, handleBackStep } = this.props;
    return (
      <section id="scrollTo">
        <ConsentForm uploadMode={uploadMode} handleNextStep={handleNextStep} copy={copy} cachedData={cachedData} handleBackStep={handleBackStep} />
      </section>
    );
  }
}
