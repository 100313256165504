import {
  BOOTSTRAP_COMPLETE,
  SUBMIT_ERROR,
  SUBMIT_ERROR_API,
  SUBMIT_PICTURE,
  SUBMIT_RECEIVE,
  SUBMIT_REQUEST,
  SUBMIT_RESET,
  SUBMIT_SET_STEP,
  SUBMIT_SET_UPLOAD_MODE,
  SUBMIT_STEP1,
  SUBMIT_STEP2,
  SUBMIT_STEP_1,
  SUBMIT_RESET_FORM,
  SUBMIT_STEP_2,
} from '../../constants/AppConstants';

const initState = {
  steps: [SUBMIT_STEP1, SUBMIT_STEP2],
  formdata: {
    STEP1: {
      uploadType: null,
      PICTURE: {
        filename: '',
        filetype: '',
        dataURL: '',
        isGif: false,
        croppedDataURL: null,
      },
      VIDEO: { filename: '', filetype: '', dataURL: '' },
      TEXT: '',
    },
  },
  currentStep: SUBMIT_STEP1,
  uploadMode: SUBMIT_PICTURE,
  isUploading: false,
  currentThemeID: '1',
  currentThemeIndex: 0,
  entrySubmitted: false,
  entrySubmitStatus: null,
  entrySubmitCount: 0,
  valid_entry_types: [],
};

const update = (state, mutations) => {
  return Object.assign({}, state, mutations);
};
const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_COMPLETE:
      nextState.valid_entry_types = action.valid_entry_types;
      return update(state, nextState);
    case SUBMIT_SET_STEP:
      nextState.currentStep = action.step;
      return update(state, nextState);
    case SUBMIT_SET_UPLOAD_MODE:
      nextState.uploadMode = action.mode;
      return update(state, nextState);
    case SUBMIT_STEP_1:
      nextState.formdata = { ...state.formdata };
      nextState.formdata.STEP1 = { ...state.formdata.STEP1 };
      nextState.formdata.STEP1[state.uploadMode] = {
        ...state.formdata.STEP1[state.uploadMode],
      };
      nextState.formdata.STEP1[state.uploadMode].filename = action.data.filename;
      nextState.formdata.STEP1[state.uploadMode].filetype = action.data.filetype;
      nextState.formdata.STEP1[state.uploadMode].dataURL = action.data.dataURL;
      nextState.formdata.STEP1[state.uploadMode].isGif = action.data.isGif || false;
      nextState.formdata.STEP1.uploadType = action.uploadType;
      nextState.currentStep = action.nextStep;
      return update(state, nextState);
    case SUBMIT_STEP_2: // picture crop step
      nextState.formdata = { ...state.formdata };
      nextState.formdata.STEP1 = { ...state.formdata.STEP1 };
      nextState.formdata.STEP1.PICTURE = { ...state.formdata.STEP1.PICTURE };
      nextState.formdata.STEP1.PICTURE.croppedDataURL = action.data.croppedDataURL;
      nextState.currentStep = action.nextStep;
      return update(state, nextState);
    case SUBMIT_REQUEST:
      nextState.isUploading = true;
      return update(state, nextState);
    case SUBMIT_RECEIVE:
      nextState.isUploading = false;
      nextState.entrySubmitted = true;
      nextState.entrySubmitStatus = action.results.status;
      nextState.formdata = { ...state.formdata };
      nextState.formdata.STEP1 = initState.formdata.STEP1;
      return update(state, nextState);
    case SUBMIT_ERROR:
    case SUBMIT_ERROR_API:
      nextState.isUploading = false;
      nextState.entrySubmitted = false;
      nextState.entrySubmitStatus = action.results.status;
      nextState.formdata = { ...state.formdata };
      nextState.formdata.STEP1 = initState.formdata.STEP1;
      return update(state, nextState);
    case SUBMIT_RESET:
      nextState.entrySubmitted = false;
      nextState.entrySubmitCount = state.entrySubmitCount + 1;
      return update(state, nextState);
    case SUBMIT_RESET_FORM:
      nextState = initState;
      return update(state, nextState);
    default:
      return state;
  }
};
export default reducer;

/**
 * SELECTORS
 */

export const getStep = (state) => {
  return state.submit.currentStep;
};
export const getUploadMode = (state) => {
  return state.submit.uploadMode;
};

export const getIsUploading = (state) => {
  return state.submit.isUploading;
};

export const getEntrySubmitStatus = (state) => {
  return {
    submitted: state.submit.entrySubmitted,
    status: state.submit.entrySubmitStatus,
  };
};

export const getFormData = (state) => {
  return state.submit.formdata.STEP1;
};

export const getSubmitFormData = (state) => {
  return state.submit.formdata;
};

export const getValidEntryTypes = (state) => {
  return state.submit.valid_entry_types;
};

export const getIsCompetition = (state) => {
  return state.submit.valid_entry_types ? true : false;
};
