import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { STATIC_PTH, SUBMIT_TEXT } from '../../constants/AppConstants';
import { setupIframeHeightPosting } from '../../utils/iframe';

import UploadMedia from '../entry/upload/UploadMedia';

export default class EntryStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooptipText: this.props.copy.txt_upload_tips_title && this.props.copy.txt_upload_tips_title != '' ? this.props.copy.txt_upload_tips_title : '',
    };
    this.handleSelectText = this.handleSelectText.bind(this);
    this.expandUploadTooltip = this.expandUploadTooltip.bind(this);
  }

  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);

    setupIframeHeightPosting();
  }

  render() {
    const { handleNextStep, copy, uploadMode, cachedData, updateUploadType, handleBackStep } = this.props;
    const {
      txt_form_title = '',
      txt_form_picture = '',
      txt_form_video = '',
      txt_form_story = '',
      txt_step1_title = '',
      txt_step1_description = '',
      txt_upload_tips_title = '',
      txt_upload_tips_please = '',
      txt_upload_tips_do_not = '',
      txt_upload_tips_send = '',
      txt_upload_tips_example1_title = '',
      txt_upload_tips_example1_description = '',
      txt_upload_tips_example2_title = '',
      txt_upload_tips_example2_description = '',
      txt_upload_tips_close = '',
    } = copy || {};
    let pictureClassList = 'form-toggle form-toggle--picture form-toggle--active';
    let storyClassList = 'form-toggle form-toggle--story form-toggle--active';
    let videoClassList = 'form-toggle form-toggle--video form-toggle--active';

    const hasImageUpload = this.props.mediaTypes.indexOf('image') !== -1;
    const hasVideoUpload = this.props.mediaTypes.indexOf('video') !== -1;
    const hasAudioUpload = this.props.mediaTypes.indexOf('audio') !== -1;
    const hasTextUpload = this.props.mediaTypes.indexOf('text') !== -1;

    return (
      <div id="scrollTo" className="wrapper b_blue tooltip-wrapper">
        <div className="tooltip-wrapper__header">
          <h1>{txt_step1_title}</h1>
          <p>
            Get a parent or guardian to help you submit your creation –
            <span className="txt_step1_important"> but make sure not to include images of people or any personal details (such as your name or address)</span>.
          </p>
        </div>
        <div className="toggles-wrapper">
          <div className="toggles-wrapper__container">
            <UploadMedia handleBackStep={handleBackStep} updateUploadType={updateUploadType} handleNextStep={handleNextStep} copy={copy} uploadMode={uploadMode} cachedData={cachedData} />
            {hasImageUpload && (
              <label htmlFor="upload_file_field_image" className={pictureClassList}>
                <img src={`${STATIC_PTH}images/upload-icon.png`} className="form-toggle__icon" />
                <div>{txt_form_picture}</div>
                <div className="subtitle">A text, photo or drawing</div>
              </label>
            )}
            {hasTextUpload && (
              <Link to={'/submit/text/step1'} onClick={this.handleSelectText} className={storyClassList}>
                <svg className="form-toggle__icon" x="0px" y="0px" width="234px" height="178px" viewBox="0 0 234 178">
                  <path
                    className="form-toggle__colour"
                    d="M194,0H70C47.9,0,30,17.9,30,40v68c0,7,1.8,13.5,4.9,19.2L0,148h70c0,0,0,0,0,0h124c22.1,0,40-17.9,40-40V40
                              C234,17.9,216.1,0,194,0z M86.3,38.2h83.3c4.4,0,8,3.6,8,8s-3.6,8-8,8H86.3c-4.4,0-8-3.6-8-8S81.8,38.2,86.3,38.2z M146.5,103.2
                              H83.3c-2.8,0-5-2.2-5-5s2.2-5,5-5h63.3c2.8,0,5,2.2,5,5S149.3,103.2,146.5,103.2z M187.5,79.2H83.3c-2.8,0-5-2.2-5-5s2.2-5,5-5
                              h104.3c2.8,0,5,2.2,5,5S190.3,79.2,187.5,79.2z"
                  />
                </svg>
                <div>{txt_form_story}</div>
              </Link>
            )}
            <p className="submitform__error" id="fileError" ref="fileError" />
          </div>
        </div>

        <div id="tooltip" className="upload-tooltip active">
          <div className="upload-tooltip__title">
            {txt_upload_tips_please} <span style={{ textDecoration: 'underline' }}>{txt_upload_tips_do_not}</span> {txt_upload_tips_send}
          </div>
          <div className="upload-tooltip__examples">
            <div className="upload-tooltip__examples__example example--1">
              <figure>
                <img src={`${STATIC_PTH}images/upload-tooltip/example_1.jpg`} alt="upload example 1" />
                <figcaption>
                  <p>{txt_upload_tips_example1_title}</p>
                  <p>{txt_upload_tips_example1_description}</p>
                </figcaption>
              </figure>
            </div>
            <div className="upload-tooltip__examples__example example--2">
              <figure>
                <img src={`${STATIC_PTH}images/upload-tooltip/example_2.jpg`} alt="upload example 2" />
                <figcaption>
                  <p>{txt_upload_tips_example2_title}</p>
                  <p>{txt_upload_tips_example2_description}</p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>

        <div id="tooltip-expand" className="expand-tooltip active" onClick={this.expandUploadTooltip}>
          <p>{this.state.tooptipText}</p>
        </div>
      </div>
    );
  }

  expandUploadTooltip() {
    const { txt_upload_tips_title = '', txt_upload_tips_close = '' } = this.props.copy;
    if (document.getElementById('tooltip').classList.contains('active')) {
      this.setState({ tooptipText: txt_upload_tips_title });
      document.getElementById('tooltip').classList.remove('active');
    } else {
      this.setState({ tooptipText: txt_upload_tips_close });

      document.getElementById('tooltip').classList.add('active');
    }

    if (document.getElementById('tooltip-expand').classList.contains('active')) {
      document.getElementById('tooltip-expand').classList.remove('active');
    } else {
      document.getElementById('tooltip-expand').classList.add('active');
    }
  }

  handleSelectText(e) {
    if (this.props.uploadMode === SUBMIT_TEXT) {
      e.preventDefault();
    }
  }
}
