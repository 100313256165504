import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SUBMIT_STEP3 } from '../../constants/AppConstants';
import ConsentFormListItem from '../entry/ConsentFormListItem';

export default class ConsentForm extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleNext(e) {
    this.props.handleNextStep({ currentStep: SUBMIT_STEP3 });
  }

  render() {
    const { uploadMode, copy } = this.props;
    const {
      btn_form_next = '',
      txt_step3_title = '',
      txt_step3_description = '',
      txt_step3_list_item1 = '',
      txt_step3_list_item2 = '',
      txt_step3_list_item3 = '',
      txt_step3_list_item4 = '',
      txt_step3_disclaimer = '',
      txt_step3_disclaimer_continued = '',
      btn_form_back = '',
      txt_step3_continue = '',
    } = copy;
    return (
      <div className="wrapper consent-form formtop">
        <div className="consent-form__container">
          <h2 className="title">{txt_step3_title}</h2>
          <p className="description">{txt_step3_description}</p>

          <div className="consent-form__bottom-section">
            <ul className="consent-form__list">
              <ConsentFormListItem text={txt_step3_list_item1} />
              <ConsentFormListItem text={txt_step3_list_item2} />
              <ConsentFormListItem text={txt_step3_list_item3} />
              <ConsentFormListItem text={txt_step3_list_item4} />
            </ul>
            <p>
              By continuing, you accept that your child&apos;s creation can be shared on the above platforms, along with your <strong>child&apos;s first name</strong>, <strong>age</strong> and
              <strong> country of residence.</strong> Only valid entries will be considered. Please check the Terms & Conditions for more information.
            </p>
            <p>{txt_step3_disclaimer_continued}</p>

            <div className="consent-form__bottom-section__buttons">
              <Link className="button back-btn" to={`/submit/${uploadMode.toLowerCase()}/step2`}>
                <span className="button_inner">
                  <span>{btn_form_back}</span>
                </span>
              </Link>

              <Link className="button next-btn" to={`/submit/${uploadMode.toLowerCase()}/step4`} onClick={this.handleNext}>
                <span className="button_inner">{txt_step3_continue}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
