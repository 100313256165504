import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getCookiePolicy, ENTRY_TEXT, kFormatter, STATIC_PTH, LOG } from './../constants/AppConstants';
import ModalMediaItem from './ModalMediaItem';

class ModalEntry extends Component {
  constructor(props) {
    super(props);

    // We check to see if OneTrust is active.
    // If so hide the likes by default and listen for the 'onetrust_init' below
    // From this we know if the user allows for performance cookies to be set
    this.state = {
      displayLikes: false,
    };
  }

  componentDidMount() {
    if (getCookiePolicy().performanceCookiesEnabled) {
      this.setState({
        displayLikes: true,
      });
    }
  }

  render() {
    const { external_id, data, entry_type, media_file, rating_count, watch_count, hasLiked, prev, next, handleLikeClick, handleCloseModal, copy } = this.props;
    const { themeImage, child_name, pet_age, pet_type, pet_food, pet_name, pet_story = '', pet_likes = '', residence = '', age = '', childs_creation = '', about_creation = '' } = data;

    // LOG.enabled && console.log(`ModalEntry`, { props: this.props, data });

    const { modal_label_pet_age = '', modal_label_pet_type = '', modal_label_name = '', modal_label_pet_food = '', modal_label_pet_story = '' } = copy;

    const trimmedResidence = (residence && residence.trim()) || '';

    const tick = `${STATIC_PTH}images/icons/tick.svg`;
    const heart = `${STATIC_PTH}images/icons/thumbs.svg`;
    const eye = `${STATIC_PTH}images/icons/view.svg`;
    const countryFlag = `${STATIC_PTH}images/flagsIcons/${trimmedResidence}.png`;

    const name = child_name || '';
    const story = pet_likes !== '' ? pet_likes : pet_story !== '' ? pet_story : '';

    const formattedWatchCount = kFormatter(watch_count);
    const formattedRatingsCount = kFormatter(rating_count);

    return (
      <div className="modal__container">
        <div className="modal__foreground modal__foreground--entry">
          <div className="modal__wrapper">
            {prev && (
              <Link to={'/creation/' + prev} href="#" className="modal__button modal__button--arrowleft">
                <span className="modal__button__icon modal__button__icon--left" />
              </Link>
            )}

            {next && (
              <Link to={'/creation/' + next} href="#" className="modal__button modal__button--arrowright">
                <span className="modal__button__icon modal__button__icon--right" />
              </Link>
            )}

            <div className="full_wrapper">
              <div className="modal__panel">
                <div className="modal__content modal__content--entry">
                  <Link to="/index" className="modal__button modal__button--close" onClick={handleCloseModal}>
                    <span className="modal__button__icon">X</span>
                  </Link>
                  <div className="entry-wrapper">
                    <div className="entry__header">{childs_creation}</div>
                    <div className="entry">
                      <div className="entry__media">
                        <ModalMediaItem src={media_file} entry_type={entry_type} />
                        <p className="entry__media__description">{about_creation}</p>
                      </div>
                    </div>
                    <div className="entry__footer">
                      <div className="entry__person">
                        <span className="entry__person-name">{`${name}, ${age}`}</span>
                      </div>
                      <div className="entry__buttons">
                        <div className="watched">
                          <button className="watched__button watched__button--black">
                            <span className="icon_container icon_container-wall">
                              <img className="icon icon_eye" src={eye} />
                            </span>
                            <span className="count count-wall">{formattedWatchCount}</span>
                          </button>
                        </div>
                        {trimmedResidence !== '' && (
                          <div className="entry__flag">
                            <img src={countryFlag} />
                          </div>
                        )}
                      </div>
                    </div>

                    {/*<div className="entry__row">*/}
                    {/*<div className="entry__favshow">*/}
                    {/*<span className="entry__favshow-lbl">{modal_footer_fav_show}</span>*/}
                    {/*<span className="entry__favshow-name"> {favourite_show}</span>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__background" onClick={handleCloseModal} />
      </div>
    );
  }
}
export default ModalEntry;
