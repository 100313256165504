import {
  APP_RATINGS_ID,
  APP_WATCH_ID,
  DYNAMIC_PTH,
  ENTRIES_RATE_ENTRY,
  ENTRIES_RATE_ENTRY_COMPLETE,
  ENTRIES_RATE_ENTRY_ERROR,
  ENTRIES_RATE_ENTRY_UPDATE,
  ENTRIES_RECEIVE,
  ENTRIES_REQUEST,
  ENTRIES_SELECT_ENTRY_ID,
  ENTRIES_SHOW_ENTRY,
  ENTRIES_VIEW_ENTRY,
  ENTRIES_VIEW_ENTRY_COMPLETE,
  ENTRIES_VIEW_ENTRY_UPDATE,
  ENTRY_IMAGE,
  ENTRY_RECEIVE,
  ENTRY_REQUEST,
  ENTRY_TEXT,
  ENTRY_VIDEO,
  EXTRA_FILTER_VALUES,
  LOG,
  MEDIA_FILTER_SELECT,
  setLikedCookie,
} from '../../constants/AppConstants';

import { fetchApprovedEntryDetailsService, getEntryDetailsService, rateEntryService, viewEntryService } from '../../services/app';

import { getHasEntries, getHasSelectedEntry, getIsMediaFilter, getLiked, getLimitByFilter, getSelectedEntryID, getSelectedFilter, getBorders, getWinners } from '../../store/entries/entriesReducer';
import { triggerEnsightenTrackingThumbnailClick } from '../../constants/EnsightenTracking';

export const selectEntryID = (externalID) => {
  triggerEnsightenTrackingThumbnailClick();
  return {
    type: ENTRIES_SELECT_ENTRY_ID,
    externalID,
  };
};

export const showSelectedEntry = (external_id) => {
  return {
    type: ENTRIES_SHOW_ENTRY,
    external_id,
  };
};

const _selectFilter = (filter) => {
  return {
    type: MEDIA_FILTER_SELECT,
    filter,
  };
};

const requestEntries = () => {
  return {
    type: ENTRIES_REQUEST,
  };
};

const receiveEntries = (entries, base_url, offset, limit, total_count, filter) => {
  return {
    type: ENTRIES_RECEIVE,
    entries: entries,
    base_url: base_url,
    offset: offset,
    limit: limit,
    total_count: parseInt(total_count),
    filter: filter,
  };
};

const requestEntry = () => {
  return {
    type: ENTRY_REQUEST,
  };
};

const receiveEntry = (entry) => {
  return {
    type: ENTRY_RECEIVE,
    entry: entry,
  };
};

const requestRateEntry = () => {
  return {
    type: ENTRIES_RATE_ENTRY,
  };
};

const requestRateEntryUpdate = (externalID, xsscookie) => {
  setLikedCookie(externalID);
  return {
    type: ENTRIES_RATE_ENTRY_UPDATE,
    externalID,
  };
};

const requestRateEntryComplete = (entry) => {
  return {
    type: ENTRIES_RATE_ENTRY_COMPLETE,
    entry,
  };
};

const requestRateEntryError = () => {
  return {
    type: ENTRIES_RATE_ENTRY_ERROR,
  };
};

const requestViewEntry = () => {
  return {
    type: ENTRIES_VIEW_ENTRY,
  };
};

const requestViewEntryUpdate = (externalID) => {
  return {
    type: ENTRIES_VIEW_ENTRY_UPDATE,
    externalID,
  };
};

const requestViewEntryComplete = (entry) => {
  return {
    type: ENTRIES_VIEW_ENTRY_COMPLETE,
    entry,
  };
};

const hasLiked = (state, id) => {
  const liked = getLiked(state);
  return (liked[id] && liked[id] === 1) || false;
};

const parseEntry = (state, o, base_url, winners, transitionDelay = 1) => {
  winners = winners || getWinners(state);
  const borders = getBorders(state);
  const max = borders.length - 1;
  const { entered, media_type, ratings: entry_ratings = [] } = o;
  const year = new Date(1000 * entered).getFullYear();
  const data = o.data ? { ...o.data } : {};
  const rating = entry_ratings.find((o) => o.rating_id === APP_RATINGS_ID) || {};
  const watch_rating = entry_ratings.find((o) => o.rating_id === APP_WATCH_ID) || {};

  const themeID = '';
  const themeImage = '';

  if (data.themeID) {
    const borderObj = borders.find((oo) => {
      return oo.id === data.themeID.toString();
    });
    if (borderObj && borderObj.image) {
      data.themeImage = DYNAMIC_PTH + borderObj.image;
    } else {
      LOG.enabledStore && console.log(`${o.external_id} : Theme ID ${data.themeID} not found, using random generated id : ${themeID}`);
      data.themeID = themeID;
      data.themeImage = themeImage;
    }
  } else {
    LOG.enabledStore && console.log(`${o.external_id} : No Theme ID found, using random generated id : ${themeID}`);
    data.themeID = themeID;
    data.themeImage = themeImage;
  }

  data.showWatermark = year <= 2015;

  data.pet_age = data.pet_age ? data.pet_age : '';
  data.pet_story = data.pet_story ? data.pet_story : '';
  data.pet_type = data.pet_type ? data.pet_type : '';
  o.data = data;

  o.transitionDelay = transitionDelay;
  o.isWinner = winners.indexOf(o.external_id) != -1;
  o.hasLiked = hasLiked(state, o.external_id);
  o.thumb_file = o.media_file ? base_url + o.media_file : null;
  console.log('... ', o);
  o.media_file = o.media_file ? base_url + o.media_file : null;
  o.rating_count = rating.rating_count || 0;
  o.watch_count = parseInt(watch_rating.rating_count) || 0;
  o.media_type = media_type ? media_type : 'text';

  /*
   *  Validates pet_type against filter list. If it exists set animal_type for filter, else set animal_type as 'other' for filter
   */
  const pet_type = o.data.pet_type && o.data.pet_type.replace(/\s+/g, '').toLowerCase();
  if (EXTRA_FILTER_VALUES.includes(pet_type)) {
    o.animal_type = pet_type;
  } else {
    o.animal_type = 'other';
  }

  switch (media_type) {
    case 'video':
      o.entry_type = ENTRY_VIDEO;
      break;
    case 'image':
      o.entry_type = ENTRY_IMAGE;
      break;
    case 'text':
      o.entry_type = ENTRY_TEXT;
      break;
    default:
      o.entry_type = ENTRY_IMAGE;
      break;
  }

  /**
   * Strip out unused properties from object
   */
  const { ratings, status, entrant, type, winner_types, prize, import_id, import_source, import_user_id, import_user_name, ...finalObj } = o;
  return finalObj;
};
export const parseEntries = (state, json, base_url, winners) => {
  return json.map((o, i) => {
    return parseEntry(state, o, base_url, winners, i);
  });
};

const fetchEntries = (state) => {
  const filter = getSelectedFilter(state).value;
  const filteredLimit = getLimitByFilter(state);
  const offset = filteredLimit.offset;
  const limit = filteredLimit.limit;
  const isMediaFilter = getIsMediaFilter(state);
  return (dispatch) => {
    dispatch(requestEntries());
    fetchApprovedEntryDetailsService(offset, limit, filter, isMediaFilter)
      .then((data) => {
        dispatch(receiveEntries(parseEntries(state, data.entries, data.base_url), data.base_url, data.offset, data.limit, data.total_count, filter));
      })
      .catch((message) => {
        console.error(message);
      });
  };
};

const fetchEntry = (state, externalID) => {
  return (dispatch, getState) => {
    dispatch(requestEntry());
    /**
     * Updates watch count then fetches, parses and shows entry
     */
    viewEntryService(externalID)
      .then((results) => {
        return getEntryDetailsService(externalID);
      })
      .then((results) => {
        dispatch(receiveEntry(parseEntry(getState(), results.entry, results.base_url)));
        dispatch(showSelectedEntry(externalID));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

const rateEntry = (state, externalID) => {
  const xsscookie = null;
  return (dispatch) => {
    dispatch(requestRateEntry());
    rateEntryService(externalID, xsscookie)
      .then((results) => {
        const xsscookie = results._xss_cookie;
        dispatch(requestRateEntryUpdate(externalID, xsscookie));
        return getEntryDetailsService(externalID);
      })
      .then((results) => {
        dispatch(requestRateEntryComplete(parseEntry(state, results.entry, results.base_url)));
      })
      .catch((response) => {
        console.error('rateEntry', response);
        dispatch(requestRateEntryError());
      });
  };
};

const viewEntry = (state, externalID) => {
  return (dispatch) => {
    viewEntryService(externalID)
      .then((results) => {
        dispatch(requestViewEntryUpdate(externalID));
        return getEntryDetailsService(externalID);
      })
      .then((results) => {
        dispatch(requestViewEntryComplete(parseEntry(state, results.entry, results.base_url)));
      })
      .catch((response) => {
        console.error(response);
      });
  };
};

export const batchFetchEntries = () => {
  return (dispatch, getState) => {
    return dispatch(fetchEntries(getState()));
  };
};

export const showEntry = () => {
  return (dispatch, getState) => {
    const state = getState();
    const externalID = getSelectedEntryID(state);
    if (getHasSelectedEntry(state)) {
      dispatch(viewEntry(state, externalID)); // track entry views (shown as count next to eye)
      dispatch(showSelectedEntry(externalID));
    } else if (externalID) {
      dispatch(fetchEntry(state, externalID));
    }
  };
};

export const likeEntry = (externalID) => {
  return (dispatch, getState) => {
    return dispatch(rateEntry(getState(), externalID));
  };
};

export const selectFilter = (filter) => {
  return (dispatch, getState) => {
    dispatch(_selectFilter(filter));
    if (!getHasEntries(getState())) {
      console.info('Has no entries - fetch some');
      dispatch(fetchEntries(getState()));
    }
  };
};
