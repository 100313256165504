import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LOG, SUBMIT_PICTURE, SUBMIT_STEP1, SUBMIT_VIDEO } from '../../../constants/AppConstants';

class MediaUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { filename: '', filetype: '', dataURL: '' };
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    const { cachedData } = this.props;
    if (cachedData) {
      this.setState({
        filename: cachedData.filename,
        filetype: cachedData.filetype,
        dataURL: cachedData.dataURL,
      });
    }
  }

  nextStep = (type) => {
    return this.props.history.push(`/submit/${type.toLowerCase()}/step2`);
  };

  render() {
    let { filename } = this.state;

    return (
      <form method="post" action="" target="upload_form_target" className="upload_form" encType="multipart/form-data" style={{ display: 'none' }}>
        <input type="hidden" name="UPLOAD_IDENTIFIER" className="UPLOAD_IDENTIFIER" value="" />
        <input type="file" name="file" id="upload_file_field_image" onChange={(e) => this._handleImageChange(e)} accept="image/jpeg,image/gif,image/png" />
      </form>
    );
  }

  isGif(fileName) {
    const splitName = fileName.split('.');
    return splitName[splitName.length - 1].toLowerCase() === 'gif';
  }

  _handleImageChange(e) {
    /**
     * https://codepen.io/hartzis/pen/VvNGZP : react image upload
     */
    e.preventDefault();
    const error = document.getElementById('fileError');
    error.textContent = '';
    error.classList.remove('active');
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      const dataURL = reader.result;
      this.setState({
        filename: file.name,
        filetype: file.type,
        dataURL: dataURL,
      });
      this.handleNext(e, SUBMIT_PICTURE);
    };

    const filesize = file.size;
    const sizeInMB = filesize / (1024 * 1024);
    LOG.enabled && console.log(`%cfilesize ${filesize}, sizeInMB ${sizeInMB}`, LOG.clrBlack, file);
    if (sizeInMB <= 4) {
      reader.readAsDataURL(file);
    } else {
      const error = document.getElementById('fileError');
      error.textContent = this.props.copy.validation_step1_imagetolarge;
      error.classList.add('active');
    }
  }

  handleVideoChange(e) {
    e.preventDefault();
    const error = document.getElementById('fileError');
    error.textContent = '';
    error.classList.remove('active');
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      const dataURL = reader.result;
      this.setState({
        filename: file.name,
        filetype: file.type,
        dataURL: dataURL,
      });
      this.handleNext(e, SUBMIT_VIDEO);
    };
    const filesize = file.size;
    const sizeInMB = filesize / (1024 * 1024);
    LOG.enabled && console.log(`%cfilesize ${filesize}, sizeInMB ${sizeInMB}`, LOG.clrBlack, file);
    if (sizeInMB <= 5) {
      reader.readAsDataURL(file);
    } else {
      error.textContent = this.props.copy.validation_step1_videotoolarge;
      error.classList.add('active');
      this.setState({ filename: '', dataURL: null });
    }
  }

  handleNext(e, type) {
    if (this.state.dataURL) {
      const fileIsGif = this.isGif(this.state.filename);
      this.props.updateUploadType(type);
      this.props.handleNextStep({
        currentStep: SUBMIT_STEP1,
        uploadType: type,
        data: {
          filename: this.state.filename,
          filetype: this.state.filetype,
          dataURL: this.state.dataURL,
          isGif: fileIsGif,
        },
      });

      this.nextStep(type);
    } else {
      if (e) {
        e.preventDefault();
      }
      if (!this.state.dataURL) {
        const error = document.getElementById('fileError');
        error.textContent = this.props.copy.validation_step1_image;
        error.classList.add('active');
      }
    }
  }
}

export default withRouter(MediaUpload);
