import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LANDING_PAGE_DATA, landingPageActive } from './../constants/AppConstants';

import { getEntryPreviewVideo, getFavouriteShows, getIsReady, getIsSingleEntry, getMediaTypes, getMinimumAge } from './../store/app/appReducer';
import { getAllCopy } from './../store/copy/copyReducer';

import { nextStep, setStep, setUploadMode, submitEntry, submitReset, submitResetFrom } from './../store/submit/submitActions';
import { getEntrySubmitStatus, getFormData, getStep, getUploadMode } from './../store/submit/submitReducer';

import SubmitController from './../components/entry/SubmitController';
import AdditionialInfo from './../components/entry/AdditionialInfo';
import Landing from './../components/entry/Landing';

class Submit extends Component {
  constructor(props) {
    super(props);
    this.selectUploadMode = this.selectUploadMode.bind(this);
    this.selectCurrentStep = this.selectCurrentStep.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleSubmitEntry = this.handleSubmitEntry.bind(this);
    this.handleResetForm = this.handleResetForm.bind(this);
    this.toggleLandingDisplay = this.toggleLandingDisplay.bind(this);
    this.handleSubmitResetForm = this.handleSubmitResetForm.bind(this);

    this.state = {
      displayLanding: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    const { currentStep, uploadMode } = params;
    this.selectCurrentStep(currentStep);
    this.selectUploadMode(uploadMode);
    this.setState({ displayLanding: landingPageActive() });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { currentStep, uploadMode } = this.props.match.params;
    const { currentStep: prevStep, uploadMode: prevUploadMode } = prevProps.match.params;
    if (uploadMode && uploadMode !== prevUploadMode) {
      this.selectUploadMode(uploadMode);
    }
    if (currentStep && currentStep !== prevStep) {
      this.selectCurrentStep(currentStep);
    }
  }

  toggleLandingDisplay() {
    this.setState({ displayLanding: !this.state.displayLanding });
  }

  render() {
    const { isReady, copy, entrySubmitStatus, minimumAge, isSingleEntry, mediaTypes, favouriteShows, cachedData, entryPreviewVideo, currentStep, uploadMode, filterOptions } = this.props;
    const { submitted, status } = entrySubmitStatus;
    const { displayLanding } = this.state;

    if (!isReady) {
      return null;
    } else if (displayLanding) {
      return (
        <div className="submit">
          <Landing toggleLandingDisplay={this.toggleLandingDisplay} />
        </div>
      );
    } else {
      return (
        <div className="submit">
          {submitted ? (
            <AdditionialInfo copy={copy} handleSubmitResetForm={this.handleSubmitResetForm} />
          ) : (
            <SubmitController
              copy={copy}
              currentStep={currentStep}
              uploadMode={uploadMode}
              minimumAge={minimumAge}
              mediaTypes={mediaTypes}
              favouriteShows={favouriteShows}
              cachedData={cachedData}
              entryPreviewVideo={entryPreviewVideo}
              handleNextStep={this.handleNextStep}
              handleBackStep={this.handleBackStep}
              handleSubmitEntry={this.handleSubmitEntry}
              updateUploadType={this.selectUploadMode}
            />
          )}
        </div>
      );
    }
  }

  selectUploadMode(id) {
    this.props.setUploadMode(id);
  }

  selectCurrentStep(id) {
    this.props.setStep(id);
  }

  handleNextStep(opts) {
    this.props.nextStep(opts);
  }

  handleBackStep(opts) {
    this.props.backStep(opts);
  }

  handleSubmitEntry(opts) {
    this.props.submitEntry(opts);
  }

  handleResetForm(e) {
    this.props.submitReset();
  }
  handleSubmitResetForm(e) {
    this.props.submitResetFrom();
  }
}

function mapStateToProps(state) {
  return {
    copy: getAllCopy(state),
    isReady: getIsReady(state),
    currentStep: getStep(state),
    uploadMode: getUploadMode(state),
    entrySubmitStatus: getEntrySubmitStatus(state),
    minimumAge: getMinimumAge(state),
    isSingleEntry: getIsSingleEntry(state),
    mediaTypes: getMediaTypes(state),
    favouriteShows: getFavouriteShows(state),
    cachedData: getFormData(state),
    entryPreviewVideo: getEntryPreviewVideo(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUploadMode: (id) => {
      dispatch(setUploadMode(id));
    },
    setStep: (id) => {
      dispatch(setStep(id));
    },
    nextStep: (opts) => {
      dispatch(nextStep(opts));
    },
    backStep: (opts) => {
      dispatch(backStep(opts));
    },
    submitEntry: (opts) => {
      dispatch(submitEntry(opts));
    },
    submitReset: () => {
      dispatch(submitReset());
    },
    submitResetFrom: () => {
      dispatch(submitResetFrom());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Submit);
