import React, { Component } from 'react';
import { getCookiePolicy, kFormatter, STATIC_PTH } from '../constants/AppConstants';
import { setupIframeHeightPosting } from '../utils/iframe';

class EntryItemDetails extends Component {
  constructor(props) {
    super(props);

    // We check to see if OneTrust is active.
    // If so hide the likes by default and listen for the 'onetrust_init' below
    // From this we know if the user allows for performance cookies to be set
    this.state = {
      displayLikes: false,
    };
  }

  componentDidMount() {
    if (getCookiePolicy().performanceCookiesEnabled) {
      this.setState({
        displayLikes: true,
      });
    }
  }

  render() {
    const tick = `${STATIC_PTH}images/icons/tick.svg`;
    const heart = `${STATIC_PTH}images/icons/thumbs.svg`;
    const eye = `${STATIC_PTH}images/icons/view.svg`;

    const { child_name, age, external_id, handleLikeClick, rating_count, watch_count, residence } = this.props;

    const trimmedResidence = (residence && residence.trim()) || '';

    const countryFlag = `${STATIC_PTH}images/flagsIcons/${trimmedResidence}.png`;

    const formattedWatchCount = kFormatter(watch_count);
    const formattedRatingsCount = kFormatter(rating_count);
    return (
      <div className="entryitem__itemdetails">
        <div className="itemdetails__person">
          <div className="itemdetails__person-name">{child_name}</div>
          <div className="itemdetails__person-age">{age}</div>
        </div>
        <div className="itemdetails__button-wrapper">
          <div>
            <button className="watched__button watched__button--black">
              <span className="icon_container icon_container-wall">
                <img className="icon icon_eye" src={eye} />
              </span>
              <span className="count count-wall">{formattedWatchCount}</span>
            </button>
          </div>

          <div>
            <div className="watched__button">
              <span className="icon_container icon_container-wall">{trimmedResidence !== '' && <img src={countryFlag} />}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EntryItemDetails;
