import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import '../sass/main.scss';
import App from './App';
import configureStore from './store/configureStore';
import {
  setDevice,
  setCookiePolicy,
  IS_MOBILE_APP,
  TurnerToons, // TODO for dev only
} from './constants/AppConstants';
import { addClosePopUpActions, addCustomActions, handleDOMReady } from './utils/mobileApp';
import { getPlatform } from './utils/platformDetection';
import { setupIframeHeightPosting } from '../js/utils/iframe';

const store = configureStore();

const doRender = (Component = App) => {
  setupIframeHeightPosting();

  render(
    <Provider store={store}>
      <Component store={store} />
    </Provider>,
    document.getElementById('root')
  );
};

const startApp = ({ device, platform }) => {
  setDevice({ device, platform });
  setCookiePolicy({
    cookiePolicy: TurnerToons.COOKIE_POLICY,
    oneTrustActiveGroups: window.OnetrustActiveGroups,
  });

  doRender(App);
};

if (IS_MOBILE_APP) {
  const platform = getPlatform();
  handleDOMReady(() => {
    addCustomActions();
    addClosePopUpActions();
    startApp({ device: platform.type, platform: platform.family });
  });
} else {
  startApp({
    device: TurnerToons.device,
    platform: TurnerToons.user_platform,
  });
}

window.addEventListener('resize', function () {
  setupIframeHeightPosting();
});

if (process.env.NODE_ENV !== 'production') {
  module.hot.accept('./App.js', () => {
    const NextRootContainer = require('./App.js').default;
    doRender(NextRootContainer);
  });
}
