import { ENTRIES_SHOW_ENTRY, MODAL_CLOSE, MODAL_CLOSED, MODAL_ENTRY, MODAL_ERROR, MODAL_OPEN, SUBMIT_ERROR, SUBMIT_ERROR_API } from '../../constants/AppConstants';

const initState = {
  isOpen: false,
  isToClose: false,
  modalType: null,
  modalProps: {},
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case ENTRIES_SHOW_ENTRY:
      return {
        isOpen: true,
        isToClose: true,
        modalType: MODAL_ENTRY,
        external_id: action.external_id,
        modalProps: action.modalProps,
      };
    case MODAL_OPEN:
      document.body.style.overflowY = 'invisible';
      return {
        isOpen: true,
        isToClose: true,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case SUBMIT_ERROR:
      return {
        isOpen: true,
        isToClose: true,
        modalType: MODAL_ERROR,
        modalProps: { status: action.results.status },
        // status   : action.results.status,
      };
    case SUBMIT_ERROR_API:
      return {
        isOpen: true,
        isToClose: true,
        modalType: MODAL_ERROR,
        modalProps: {
          status: action.results.status,
          statusCode: action.results.statusCode,
        },
        // status    : action.results.status,
        // statusCode: action.results.statusCode,
        // reason    : action.results.reason,
      };
    case MODAL_CLOSE:
      document.body.style.overflowY = 'auto';
      return {
        isOpen: false,
        isToClose: true,
        modalType: null,
        modalProps: {},
      };
    case MODAL_CLOSED:
      return initState;
    default:
      return state;
  }
};
export default reducer;

/**
 * SELECTORS
 */
export const getModal = (state) => {
  return state.modal;
};

export const getIsModalOpen = (state) => {
  return state.modal.isOpen;
};

export const getIsModalClosing = (state) => {
  return state.modal.isToClose;
};
