import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsFetching } from './../store/entries/entriesReducer';
import { STATIC_PTH } from './../constants/AppConstants';

class Loader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isVisible = this.props.isFetching || false;
    const spinner = `${STATIC_PTH}images/spinner.svg`;
    return (
      <div>
        {isVisible && (
          <div className="loading">
            <div className="icon_container">
              <img className="icon icon_spin" src={spinner} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: getIsFetching(state),
  };
}

export default connect(mapStateToProps)(Loader);
