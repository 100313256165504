import React from 'react';
import EntryItem from './EntryItem';

const Entries = (props) => {
  const { base_url, entries, copy } = props;
  let renderEntry = entries.map((data, index) => {
    return <EntryItem key={data.external_id} handleLikeClick={props.handleLikeClick} copy={copy} index={index} {...data} />;
  });

  return <div className="entrylist">{renderEntry}</div>;
};
export default Entries;
