import {
  LOG,
  SUBMIT_ERROR,
  SUBMIT_ERROR_API,
  SUBMIT_PICTURE,
  SUBMIT_RECEIVE,
  SUBMIT_REQUEST,
  SUBMIT_RESET,
  SUBMIT_SET_STEP,
  SUBMIT_SET_UPLOAD_MODE,
  SUBMIT_STEP1,
  SUBMIT_STEP2,
  SUBMIT_STEP3,
  SUBMIT_STEP4,
  SUBMIT_STEP_1,
  SUBMIT_STEP_2,
  SUBMIT_STEP_3,
  SUBMIT_TEXT,
  SUBMIT_VIDEO,
  SUBMIT_RESET_FORM,
} from '../../constants/AppConstants';

import { submitEntryService } from '../../services/app';
import { getSubmitFormData, getValidEntryTypes } from '../submit/submitReducer';
import { triggerEnsightenTrackingEntrySubmit, triggerEnsightenTrackingPageView } from '../../constants/EnsightenTracking';

export const setStep = (step) => {
  step = step ? step.toUpperCase() : step;
  triggerEnsightenTrackingPageView({ stepId: step });
  return {
    type: SUBMIT_SET_STEP,
    step,
  };
};

export const setUploadMode = (mode) => {
  mode = mode ? mode.toUpperCase() : mode;
  return {
    type: SUBMIT_SET_UPLOAD_MODE,
    mode,
  };
};

export const submitResetFrom = () => {
  return {
    type: SUBMIT_RESET_FORM,
  };
};

export const submitReset = () => {
  return {
    type: SUBMIT_RESET,
  };
};

export const backStep = (opts) => {
  const { currentStep } = opts;
  switch (currentStep) {
    case SUBMIT_STEP2:
      return {
        type: SUBMIT_STEP_1,
        nextStep: SUBMIT_STEP2,
      };
    case SUBMIT_STEP3:
      return {
        type: SUBMIT_STEP_2,
        nextStep: SUBMIT_STEP3,
      };
    case SUBMIT_STEP4:
      return {
        type: SUBMIT_STEP_3,
        nextStep: SUBMIT_STEP4,
      };
  }
};

export const nextStep = (opts) => {
  const { currentStep, uploadType, data } = opts;
  switch (currentStep) {
    case SUBMIT_STEP1:
      return {
        type: SUBMIT_STEP_1,
        nextStep: SUBMIT_STEP2,
        uploadType,
        data,
      };
    case SUBMIT_STEP2:
      return {
        type: SUBMIT_STEP_2,
        nextStep: SUBMIT_STEP3,
        data,
      };
    case SUBMIT_STEP3:
      return {
        type: SUBMIT_STEP_3,
        nextStep: SUBMIT_STEP4,
      };
  }
};

const requestSubmit = () => {
  return {
    type: SUBMIT_REQUEST,
  };
};

const receiveSubmit = (results) => {
  return {
    type: SUBMIT_RECEIVE,
    results,
  };
};

const receiveSubmitError = (results) => {
  return {
    type: SUBMIT_ERROR,
    results,
  };
};

const receiveSubmitAPIError = (results) => {
  return {
    type: SUBMIT_ERROR_API,
    results,
  };
};

const submit = (state, data) => {
  const validEntryTypes = getValidEntryTypes(state);
  const formData = getSubmitFormData(state);
  const year = new Date().getFullYear();
  const dob = '01/01/' + (year - data.age).toString();

  // split up the full_name
  let firstName = 'na';
  let surname = 'na';

  const { full_name = '', email, first_name, age, emailpermission } = data;
  const { about_creation, childs_creation, residence } = data;

  if (data.full_name != '') {
    firstName = data.full_name.split(' ')[0];
    if (data.full_name.split(' ')[1]) {
      surname = data.full_name.replace(`${firstName} `, '');
    }
  }

  const submit_data = {
    social_type: 'none',
    first_name: firstName,
    last_name: surname,
    email: email,
    dob: dob,
    data: JSON.stringify({}),
    private_data: JSON.stringify({}),
    entrant_private_data: JSON.stringify({}),
  };
  if (validEntryTypes) {
    submit_data.type = validEntryTypes[0];
  }

  const privateData = {
    childName: first_name,
    parentName: firstName,
    parentSurname: surname,
  };

  const details = {
    child_name: first_name,
    age: age,
    about_creation: about_creation,
    childs_creation: childs_creation,
    residence: residence,
    emailpermission: emailpermission,
  };

  const uploadType = formData.STEP1.uploadType;

  switch (uploadType) {
    case SUBMIT_PICTURE:
      if (formData.STEP1.PICTURE.isGif) {
        submit_data.file = formData.STEP1.PICTURE.dataURL;
      } else if (formData.STEP1.PICTURE.croppedDataURL) {
        submit_data.file = formData.STEP1.PICTURE.croppedDataURL;
      } else {
        submit_data.file = formData.STEP1.PICTURE.dataURL;
      }
      break;
    case SUBMIT_VIDEO:
      submit_data.file = formData.STEP1.VIDEO.dataURL;
      break;
  }

  submit_data.data = JSON.stringify(details);
  submit_data.private_data = JSON.stringify(privateData);

  LOG.enabledStore && console.log('submit_data', submit_data);
  triggerEnsightenTrackingEntrySubmit();

  return (dispatch) => {
    dispatch(requestSubmit());
    submitEntryService(submit_data)
      .then((results) => {
        dispatch(receiveSubmit({ status: 'SUCCESS' }));
      })
      .catch((response) => {
        if (response.statusCode === 200) {
          // good response so handle api error
          const error_details = JSON.parse(response.text);
          if (error_details.reason === 'ALREADY_ENTERED' || error_details.reason === 'ENTRY_COUNT_EXCEEDED') {
            dispatch(receiveSubmit({ status: error_details.reason }));
          } else {
            dispatch(receiveSubmitError({ status: error_details.reason }));
          }
          console.warn(error_details);
        } else {
          dispatch(
            receiveSubmitAPIError({
              status: response.statusText,
              statusCode: response.statusCode,
            })
          );
          console.error(response);
          throw new Error(response.stack);
        }
      });
  };
};

export const submitEntry = (data) => {
  return (dispatch, getState) => {
    return dispatch(submit(getState(), data));
  };
};
