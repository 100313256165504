import { BOOTSTRAP_COMPLETE, BOOTSTRAP_START, MEDIA_FILTER_ALL, RECEIVE_WINNERS_COMPLETE, RECEIVE_WINNERS_START, SUBMIT_ERROR, SUBMIT_ERROR_API, SUBMIT_VIDEO_URL } from '../../constants/AppConstants';

import { fetchApprovedEntryDetailsService, getAppDetailsService, getFavouriteShowsService, getWinnersService } from '../../services/app';
import { parseEntries } from '../entries/entriesActions';

const startBootstrap = () => {
  return {
    type: BOOTSTRAP_START,
  };
};

const bootstrapError = (results) => {
  return {
    type: SUBMIT_ERROR,
    results,
  };
};

const bootstrapAPIError = (results) => {
  return {
    type: SUBMIT_ERROR_API,
    results,
  };
};

const receiveWinners = () => {
  return {
    type: RECEIVE_WINNERS_START,
  };
};
const receiveWinnersComplete = (winners) => {
  return {
    type: RECEIVE_WINNERS_COMPLETE,
    winners: winners,
  };
};

const completeBootstrap = (appData, favShows, entries, offset, limit, total_count) => {
  const { app } = appData;
  const { media_types } = app;
  const { data } = app;
  const { valid_entry_types } = data;
  const media_file = SUBMIT_VIDEO_URL ? SUBMIT_VIDEO_URL : null;

  /**
   * filter out potentially invalid media types (pdf and zip currently)
   */
  const valid_media_types = media_types.reduce((obj, key) => {
    if ('image,video,audio'.includes(key)) {
      obj.push(key);
    }
    return obj;
  }, []);

  return {
    type: BOOTSTRAP_COMPLETE,
    base_url: appData.base_url,
    entry_preview_video: media_file,
    appData: app,
    valid_entry_types: valid_entry_types,
    min_age: app.min_age,
    entryMode: app.entry_mode,
    entryLimit: app.entry_limit,
    mediaTypes: valid_media_types,
    favShows: favShows,
    filter: MEDIA_FILTER_ALL,
    entries: entries,
    offset: offset,
    limit: limit,
    total_count: parseInt(total_count),
  };
};

const parseFavouriteShows = (json, uppercaseOptions = true) => {
  let reformattedArray = json.map(function (val) {
    let o = { value: '', label: '' };
    o.value = val.name.replace(/\s+/g, '').toLowerCase();
    let label = val.name;
    o.label = uppercaseOptions ? label.toUpperCase() : label;
    return o;
  });
  return reformattedArray;
};

const parseWinners = (json) => {
  return json
    .map((o) => {
      return o.external_id;
    })
    .toString();
};

const doBootstrap = (state) => {
  return (dispatch) => {
    dispatch(startBootstrap());

    Promise.all([getAppDetailsService(), getFavouriteShowsService(), fetchApprovedEntryDetailsService()])
      .then((results) => {
        const data = results[2];
        dispatch(completeBootstrap(results[0], parseFavouriteShows(results[1]), parseEntries(state, data.entries, data.base_url), data.offset, data.limit, data.total_count));
      })
      .catch((response) => {
        if (response.statusCode === 200) {
          // good response so handle api error
          const error_details = JSON.parse(response.text);
          dispatch(bootstrapError({ status: error_details.reason }));
          console.warn(error_details);
        } else {
          dispatch(
            bootstrapAPIError({
              status: response.statusText,
              statusCode: response.statusCode,
            })
          );
          console.error(response);
          throw new Error(response.stack);
        }
      });
  };
};

export const bootstrap = () => {
  return (dispatch, getState) => {
    dispatch(receiveWinners());
    getWinnersService()
      .then((data) => {
        dispatch(receiveWinnersComplete(parseWinners(data)));
        dispatch(doBootstrap(getState()));
      })
      .catch((message) => {
        console.error(message);
        // ok, winners is failing, move on as its not going to break the app
        dispatch(doBootstrap(getState()));
      });
  };
};
