!(function (t, n) {
  'use strict';
  function E(n) {
    let o = n.target,
      i = n.origin;
    if (!(this instanceof E)) return new E(n);
    if ('undefined' == typeof o || !('postMessage' in o)) throw new Error('CNComm - The provided target "window" reference doesn\'t exists or "postMessage" is not supported.');
    if (!s(i)) throw new Error('CNComm - The provided origin value is not an Array.');
    return (
      (this._callbacks = []),
      (this._target = o),
      (this._origin = i),
      (this._origin_length = this._origin.length),
      'function' == typeof n.onMessage && (this._onMessageHandler = n.onMessage),
      r(t, 'message', this._onMessage.bind(this)),
      this
    );
  }
  function o() {
    return ('0000' + ((Math.random() * Math.pow(36, 4)) << 0).toString(36)).slice(-4);
  }
  function i() {
    try {
      return window.self !== window.top;
    } catch (t) {
      return !0;
    }
  }
  function r(t, n, e) {
    t.addEventListener ? t.addEventListener(n, e, !1) : t.attachEvent ? t.attachEvent('on' + n, e) : (t['on' + n] = e);
  }
  function s(t) {
    return '[object Array]' === Object.prototype.toString.call(t);
  }
  function a(t, n) {
    let e, o;
    if (Array.prototype.indexOf) return n.indexOf(t);
    for (e = 0, o = n.length; o > e; e++) if (t === n[e]) return e;
    return -1;
  }
  let c = i(),
    p = {};
  (E.prototype.ready = c
    ? function (t) {
        return this._ping(t), this;
      }
    : n),
    (E.prototype.request = c
      ? function (t, n) {
          if (((t.type = 'request'), (t.callback_id = this._registerCallback(n)), 'undefined' != typeof t.args && !s(t.args))) throw new Error('CNComm - Property "args" is not an Array');
          this._post(t);
        }
      : n),
    (E.prototype.response = c
      ? n
      : function (t) {
          (t.type = 'response'), this._post(t);
        }),
    (E.prototype._post = function (t) {
      for (let n = 0; n < this._origin_length; n++) this._target.postMessage(JSON.stringify(t), '*');
    }),
    (E.prototype._ping = function (n) {
      let e = { type: 'ping', callback_id: this._registerCallback(n) },
        o = 0,
        i = 10;
      p[e.callback_id] = t.setInterval(
        (function (n) {
          return function () {
            if (o === i) throw (t.clearInterval(p[e.callback_id]), new Error('CNComm - Timed out.'));
            o++, n._post(e);
          };
        })(this),
        1e3
      );
    }),
    (E.prototype._pong = function (t) {
      let n = { type: 'pong', callback_id: t.callback_id };
      this._post(n);
    }),
    (E.prototype._registerCallback = function (t) {
      let n;
      if ('function' != typeof t) throw new Error('CNComm - No callback function provided.');
      return (n = o()), (this._callbacks[n] = t), n;
    }),
    (E.prototype._onMessage = function (t) {
      let n,
        e = t.origin || t.originalEvent.origin;
      if (-1 !== a(e, this._origin))
        switch (((n = JSON.parse(t.data)), n.type)) {
          case 'ping':
            this._onPing(n);
            break;
          case 'pong':
            this._onPong(e, n);
            break;
          case 'request':
            this._onRequest(n);
            break;
          case 'response':
            this._onResponse(n);
        }
    }),
    (E.prototype._onRequest = function (t) {
      this._onMessageHandler(t);
    }),
    (E.prototype._onResponse = function (t) {
      'function' == typeof this._callbacks[t.callback_id] && (this._callbacks[t.callback_id](t.results), t.isPermanent || delete this._callbacks[t.callback_id]);
    }),
    (E.prototype._onPing = function (t) {
      this._pong(t);
    }),
    (E.prototype._onPong = function (n, e) {
      (this._origin = [n]), (this._origin_length = this._origin.length), t.clearInterval(p[e.callback_id]), this._onResponse(e);
    }),
    (t.CNComm = { init: E });
})(window);
/* Desktop - Version 3.1.73 */

/*
 * TODO : TURNER IFRAME COMMUNICATION
 */

export const IS_IFRAMED = window.self !== window.top;
let proxy;

export const setupIframeHeightPosting = () => {
  if (IS_IFRAMED) {
    iframeHeightPost();

    /* TURNER IFRAME COMMUNICATION */
    if (window.CNComm) {
      proxy = window.CNComm.init({
        target: window.parent,
        origin: ['https://www.cartoonnetwork.co.uk', 'https://www.cartoonnetworkhq.com ', 'https://cnu.turner-apps.com"'],
      });

      if (proxy.ready) {
        //ready wont exist if domain is same as window.top
        proxy.ready(function () {
          console.log('CNCommProxy Ready');
          iframeHeightPost();
        });
      }
    }

    /* TURNER IFRAME COMMUNICATION */
  }
};

export const iframeHeightPost = () => {
  const rootElement = document.getElementById('app-wrapper');
  const height = rootElement.clientHeight;
  console.log('iframeHeightPost', height);
  window.top.postMessage('documentHeight:' + height + 100, '*');
  /* TURNER IFRAME COMMUNICATION */
  if (proxy && proxy.request && height) {
    console.log('SENDING CNCOMM');
    proxy.request(
      {
        namespace: 'CN',
        method: 'setHeight',
        args: [height + 100 + 'px'],
      },
      function () {
        /*Done*/
      }
    );
  }
  /* TURNER IFRAME COMMUNICATION */
};

/**
 * window.scrollTo(0,$("#start_anchor").offset().top);
 * iframeScrollPost($("#start_anchor").offset().top);
 */
export const iframeScrollPost = (el) => {
  if (IS_IFRAMED) {
    const position = el.offset().top;
    console.log('iframeScrollPost', position);
    window.top.postMessage('scrollPageY:' + position, '*');
    /* TURNER IFRAME COMMUNICATION */
    if (proxy && proxy.request) {
      proxy.request(
        {
          namespace: 'CN',
          method: 'setScrollTop',
          args: [_targetPos + 'px', 500],
        },
        function () {
          /*Done*/
        }
      );
    }
    /* TURNER IFRAME COMMUNICATION */
  }
};
