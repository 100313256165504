import React, { Component } from 'react';

import PersonForm from '../entry/PersonForm';

export default class EntryStep4 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
  }

  render() {
    const {
      copy = {},
      currentStep,
      uploadMode,
      minimumAge,
      mediaTypes,
      favouriteShows,
      cachedData,
      entryPreviewVideo,
      handleNextStep,
      handleSubmitEntry,
      handleBackStep,
      updateUploadType,
    } = this.props;
    const fileIsGif = (cachedData && cachedData.PICTURE && cachedData.PICTURE.isGif) || false; // cant crop gifs
    return (
      <section id="scrollTo">
        <PersonForm
          showAdditionialInfo={this.showAdditionialInfo}
          handleSubmitEntry={handleSubmitEntry}
          copy={copy}
          uploadMode={uploadMode}
          fileIsGif={fileIsGif}
          minimumAge={minimumAge}
          favouriteShows={favouriteShows}
        />
      </section>
    );
  }
}
