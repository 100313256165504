import React, { Component, createRef } from 'react';
import { LANDING_PAGE_DATA, DYNAMIC_PTH } from '../../constants/AppConstants';
import { processImages } from '../../utils/image';

export default class Landing extends Component {
  constructor(props) {
    super(props);

    this.popupRef = createRef();
    this.displayPopup = this.displayPopup.bind(this);
  }

  displayPopup() {
    if (this.popupRef.current) {
      this.popupRef.current.classList.value.indexOf('active') !== -1 ? this.popupRef.current.classList.remove('active') : this.popupRef.current.classList.add('active');
    }
  }

  render() {
    const { toggleLandingDisplay } = this.props;
    const {
      title = 'Competition Title',
      body = 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      prize_image = '',
      legal_terms = 'link',
      legal_link = '#',
      enter_button = 'enter',
    } = LANDING_PAGE_DATA;

    const { 0: img_large = {}, 1: img_medium = {}, 2: img_small = {} } = prize_image;
    const { file_name: img_large_file = '', width: img_large_width = 0 } = img_large;
    const { file_name: img_medium_file = '', width: img_medium_width = 0 } = img_medium;
    const { file_name: img_small_file = '', width: img_small_width = 0 } = img_small;

    const { placeholder_lazy, srcSet, src } = processImages(
      '',
      [DYNAMIC_PTH + img_large_file, DYNAMIC_PTH + img_medium_file, DYNAMIC_PTH + img_small_file],
      [`${img_large_width}w`, `${img_medium_width}w`, `${img_small_width}w`]
    );

    return (
      <>
        <div className="popup popup_content_scrolling" ref={this.popupRef}>
          <div className="close_btn js_close_popups" onClick={this.displayPopup}>
            X
          </div>
          <div className="copy" dangerouslySetInnerHTML={{ __html: legal_terms }} />
        </div>
        <div className="wrapper entry-landing-page">
          <h2 className="entry-landing-page__title">{title}</h2>
          <div className="entry-landing-page__body" dangerouslySetInnerHTML={{ __html: body }} />

          <div className="entry-landing-page__image">
            <figure>
              <img src={DYNAMIC_PTH + img_large_file} srcSet={srcSet} alt={title} title={title} />
            </figure>
          </div>

          <div className="entry-landing-page__center-content">
            <button className="entry-landing-page__link" onClick={this.displayPopup}>
              {legal_link}
            </button>

            <div className="button next-btn" onClick={toggleLandingDisplay}>
              <span className="button_inner">
                <span>{enter_button}</span>
              </span>
            </div>
          </div>

          <div className="entry-landing-page__character" />
        </div>
      </>
    );
  }
}
