import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { STATIC_PTH, SUBMIT_PICTURE, SUBMIT_VIDEO } from '../../constants/AppConstants';

import PictureCropper from '../entry/upload/PictureCropper';

class EntryStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
    };

    this.nextStep = this.nextStep.bind(this);
  }

  nextStep = () => {
    const { uploadMode } = this.props;
    return this.props.history.push(`/submit/${uploadMode.toLowerCase()}/step3`);
  };

  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
    const { uploadMode } = this.props;

    if (uploadMode === SUBMIT_VIDEO) {
      this.setState({ timer: setTimeout(this.nextStep, 3000) });
    }
  }

  componentWillUnmount() {
    if (this.state.timer != null) {
      clearTimeout(this.state.timer);
    }
  }

  render() {
    const { handleNextStep, copy, uploadMode, cachedData, handleBackStep } = this.props;
    const { btn_form_next = '', txt_step2_title_video = '', txt_step2_description_video = '', btn_form_back = '' } = copy;
    return (
      <section id="scrollTo">
        {uploadMode === SUBMIT_PICTURE && <PictureCropper handleNextStep={handleNextStep} copy={copy} cachedData={cachedData.PICTURE} />}
        {uploadMode === SUBMIT_VIDEO && (
          <React.Fragment>
            <div className="wrapper b_purple wall">
              <div className="formtop submitform__step2">
                <h2>{txt_step2_title_video}</h2>

                <div>
                  <p className="description">{txt_step2_description_video}</p>
                </div>

                <div className="photo-panel">
                  <div className="photo-panel__wrapper">
                    <div className="photo-panel__image">
                      <img src={`${STATIC_PTH}images/scooby-camera.gif`} alt="placeholder video uplodd gif" />
                    </div>

                    <div className="photo-buttons">
                      <Link className="button" to={`/submit/${uploadMode.toLowerCase()}/step1`}>
                        <button className="back-btn">{btn_form_back}</button>
                      </Link>
                      <Link className="button" to={`/submit/${uploadMode.toLowerCase()}/step3`} onClick={this.handleNext}>
                        <button className="next-btn">{btn_form_next}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default withRouter(EntryStep2);
