import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { STATIC_PTH, SUBMIT_STEP2 } from '../../../constants/AppConstants';

import Cropper from 'cropperjs';
import Watermark from '../../entry/upload/Watermark';

class PictureCropper extends Component {
  constructor(props) {
    super(props);
    this.cropper = null;
    this.cropperOptions = {
      guides: false,
      modal: false,
      highlight: false,
      center: false,

      cropBoxMovable: false,
      cropBoxResizable: false,
      dragMode: 'move',
      dragCrop: false,
      aspectRatio: 1,
      autoCropArea: 1,
      viewMode: 3,

      crop() {
        const cropContainer = document.querySelectorAll('.cropperjser')[0];
        cropContainer.style.height = cropContainer.offsetWidth + 'px';
      },
      ready() {
        const cropContainer = document.querySelectorAll('.cropperjser')[0];
        cropContainer.style.height = cropContainer.offsetWidth + 'px';
      },
    };
    this.handleNext = this.handleNext.bind(this);
    this.move = this.move.bind(this);
    this.zoom = this.zoom.bind(this);
    this.rotate = this.rotate.bind(this);
  }

  componentWillUnmount() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  componentDidMount() {
    let scrollTarget = document.querySelectorAll('.formtop')[0];
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
    const { cachedData } = this.props;
    const { filename, filetype, dataURL, croppedDataURL } = cachedData;
    const cropContainer = document.querySelectorAll('.cropperjser')[0];
    cropContainer && (cropContainer.style.height = cropContainer.offsetWidth + 'px');

    const _img = document.getElementById('photo_editor_canvas');
    _img.onload = () => {
      this.cropper = new Cropper(_img, this.cropperOptions);
    };
    _img.src = dataURL;
  }

  render() {
    const { copy } = this.props;
    const static_pth = STATIC_PTH;

    const zoom_out_style = {
      backgroundImage: `url(${static_pth}images/icons/zoom_out.svg)`,
    };
    const arrow_left_style = {
      backgroundImage: `url(${static_pth}images/icons/arrow_left.svg)`,
    };
    const arrow_right_style = {
      backgroundImage: `url(${static_pth}images/icons/arrow_right.svg)`,
    };
    const arrow_up_style = {
      backgroundImage: `url(${static_pth}images/icons/arrow_up.svg)`,
    };
    const arrow_down_style = {
      backgroundImage: `url(${static_pth}images/icons/arrow_down.svg)`,
    };
    const rotate_style = {
      backgroundImage: `url(${static_pth}images/icons/rotate.svg)`,
    };

    const { txt_step1_pic_crop_title = '', txt_step1_crop_help = '', btn_form_next = '', txt_step = '', txt_of = '', btn_form_back = '' } = copy || {};

    const useWatermark = true;

    return (
      <section>
        <div className="wrapper b_purple wall">
          <div className="formtop submitform__step2">
            <h2>{txt_step1_pic_crop_title}</h2>

            <div>
              <p className="description">{txt_step1_crop_help}</p>
            </div>

            <div className="photo-panel">
              <div className="photo-panel__wrapper">
                <div className="photo-panel__image">
                  <div className="cropperjser cropperjser--square">
                    <img id="photo_editor_canvas" />
                    {useWatermark && <Watermark />}
                  </div>
                </div>

                <div className="photo-buttons">
                  <Link className="button" to={'/submit/picture/step1'}>
                    <button className="back-btn">{btn_form_back}</button>
                  </Link>
                  <button className="photo-button photo-button--zoom-in" data-zoom="0.1" onClick={this.zoom} />
                  <button className="photo-button photo-button--zoom-out" data-zoom="-0.1" onClick={this.zoom} />
                  <button className="photo-button photo-button--move-left" data-movex="-20" data-movey="0" onClick={this.move} />
                  <button className="photo-button photo-button--move-right" data-movex="20" data-movey="0" onClick={this.move} />
                  <button className="photo-button photo-button--move-up" data-movex="0" data-movey="-20" onClick={this.move} />
                  <button className="photo-button photo-button--move-down" data-movex="00" data-movey="20" onClick={this.move} />
                  <Link className="button" to={'/submit/picture/step3'} onClick={this.handleNext}>
                    <button className="next-btn">{btn_form_next}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  move(e) {
    if (this.cropper) {
      this.cropper.move(e.currentTarget.getAttribute('data-movex'), e.currentTarget.getAttribute('data-movey'));
    }
  }

  rotate() {
    if (this.cropper) {
      this.cropper.rotate(90);
    }
  }

  zoom(e) {
    if (this.cropper) {
      this.cropper.zoom(e.currentTarget.getAttribute('data-zoom'));
    }
  }

  handleNext(e) {
    const { cachedData } = this.props;
    const { filename, filetype, dataURL, croppedDataURL } = cachedData;
    console.info('Sending base64 data as ', filetype);
    let croppedData = this.cropper.getCroppedCanvas().toDataURL(filetype, 0.75);
    if (!croppedData) {
      croppedData = this.props.cachedData.dataURL;
    }
    this.props.handleNextStep({
      currentStep: SUBMIT_STEP2,
      data: { croppedDataURL: croppedData },
    });
  }
}

PictureCropper.defaultProps = {
  dataURL: '',
  copy: [],
};

export default PictureCropper;
