import { combineReducers } from 'redux';
import app from './app/appReducer';
import copy from './copy/copyReducer';
import modal from './modal/modalReducer';
import entries, { selectedEntryID, visibleEntry, selectedFilter } from './entries/entriesReducer';
import submit from './submit/submitReducer';

const rootReducer = combineReducers({
  app: app,
  copy: copy,
  modal: modal,
  entries: entries,
  selectedEntryID: selectedEntryID,
  visibleEntry: visibleEntry,
  submit: submit,
  // filter         : filter,
  selectedFilter: selectedFilter,
});

export default rootReducer;
