import React, { Component } from 'react';
import { SUBMIT_PICTURE, SUBMIT_STEP1, SUBMIT_STEP2, SUBMIT_STEP3, SUBMIT_STEP4, SUBMIT_STEP5 } from '../../constants/AppConstants';

import EntryStep1 from '../steps/EntryStep1';
import EntryStep2 from '../steps/EntryStep2';
import EntryStep3 from '../steps/EntryStep3';
import EntryStep4 from '../steps/EntryStep4';
import EntryStep5 from '../steps/EntryStep5';

export default class SubmitController extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      copy = {},
      currentStep,
      uploadMode,
      minimumAge,
      mediaTypes,
      favouriteShows,
      cachedData,
      entryPreviewVideo,
      handleNextStep,
      handleSubmitEntry,
      handleBackStep,
      updateUploadType,
    } = this.props;

    const fileIsGif = (cachedData && cachedData.PICTURE && cachedData.PICTURE.isGif) || false; // cant crop gifs

    const { txt_form_title = '', txt_form_picture = '', txt_form_video = '', txt_form_story = '' } = copy || {};

    const step1 = currentStep === SUBMIT_STEP1;
    const step2 = currentStep === SUBMIT_STEP2;
    const step3 = currentStep === SUBMIT_STEP3;
    const step4 = currentStep === SUBMIT_STEP4;
    const step5 = currentStep === SUBMIT_STEP5;

    const canCrop = uploadMode === SUBMIT_PICTURE && !fileIsGif;
    const showUpload = currentStep === SUBMIT_STEP1;
    const showCropper = currentStep === SUBMIT_STEP2 && canCrop;
    const showConsent = currentStep === SUBMIT_STEP3;
    const showForm = (currentStep === SUBMIT_STEP5 && !canCrop) || (currentStep === SUBMIT_STEP5 && uploadMode === SUBMIT_PICTURE);

    return (
      <div className="submitform">
        <section className="submitform-hero">
          {step1 && <EntryStep1 updateUploadType={updateUploadType} mediaTypes={mediaTypes} copy={copy} handleNextStep={handleNextStep} uploadMode={uploadMode} cachedData={cachedData} />}
        </section>
        {step2 && <EntryStep2 handleBackStep={handleBackStep} uploadMode={uploadMode} handleNextStep={handleNextStep} copy={copy} cachedData={cachedData} />}
        {step3 && <EntryStep3 handleBackStep={handleBackStep} uploadMode={uploadMode} handleNextStep={handleNextStep} copy={copy} cachedData={cachedData} />}
        {step4 && <EntryStep4 {...this.props} />}
        {step5 && <EntryStep5 {...this.props} />}
      </div>
    );
  }
}
