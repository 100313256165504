import React from 'react';

const ModalError = (props) => {
  const { status, statusCode = '' } = props;
  return (
    <div className="modal__container">
      <div className="modal__foreground modal__foreground--entry">
        <div className="full_wrapper">
          <div className="modal__panel">
            <div className="modal__content modal__content--error">
              <a href="#" className="modal__button modal__button--close" onClick={props.handleCloseModal}>
                <span className="modal__button__icon">X</span>
              </a>

              <div className="error">
                <div className="error__details">
                  <h1>Ooops!!</h1>
                  <h2>It looks like something has gone wrong.</h2>
                  <p>
                    Reason :{' '}
                    <small>
                      {status} {statusCode}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__background" onClick={props.handleCloseModal} />
    </div>
  );
};
export default ModalError;
