import {
  BOOTSTRAP_COMPLETE,
  BORDERS,
  DEFAULT_ENTRY_QUERY_LIMIT,
  ENTRIES_RATE_ENTRY,
  ENTRIES_RATE_ENTRY_COMPLETE,
  ENTRIES_RATE_ENTRY_ERROR,
  ENTRIES_RATE_ENTRY_UPDATE,
  ENTRIES_RECEIVE,
  ENTRIES_REQUEST,
  ENTRIES_SELECT_ENTRY_ID,
  ENTRIES_SHOW_ENTRY,
  ENTRIES_VIEW_ENTRY_COMPLETE,
  ENTRY_RECEIVE,
  ENTRY_REQUEST,
  EXTRA_FILTERS,
  getLikedCookie,
  MEDIA_FILTER_ALL,
  MEDIA_FILTER_SELECT,
  MODAL_CLOSED,
  RECEIVE_WINNERS_COMPLETE,
  SUBMIT_ERROR,
  SUBMIT_ERROR_API,
  LANGUAGE_TEXTS,
} from '../../constants/AppConstants';

const media_filters = [MEDIA_FILTER_ALL, 'video', 'image']; // others ['text', 'audio'];
const filterOptions = [];
for (const filter of media_filters) {
  filterOptions.push({
    value: filter,
    label: LANGUAGE_TEXTS['potw_txt_drop_option_' + filter],
  });
}

const allFilterOptions = [...filterOptions, ...EXTRA_FILTERS];
const allIds = allFilterOptions.map((o, i) => {
  return o.value;
});

const byID = {};

for (const id of allIds) {
  byID[id] = {
    id: id,
    items: [],
    offset: 0,
    limit: DEFAULT_ENTRY_QUERY_LIMIT,
    total_count: DEFAULT_ENTRY_QUERY_LIMIT + 1,
    verified: false,
  };
}

const initState = {
  borders: BORDERS,
  offset: 0, // start index for entries to retrieve
  limit: DEFAULT_ENTRY_QUERY_LIMIT, // number of entries to retrieve
  categories: { byID: byID, allIds: allIds },
  items: { byID: {}, allIds: [] },
  filterOptions: allFilterOptions,
  media_filters: media_filters,
  winners: '',
  base_url: '',
  total_count: 0,
  liked: getLikedCookie(),
  isFetching: true,
};

export const selectedFilter = (state = initState.filterOptions[0], action) => {
  switch (action.type) {
    case MEDIA_FILTER_SELECT:
      return action.filter;
    default:
      return state;
  }
};

export const selectedEntryID = (state = '', action) => {
  switch (action.type) {
    case MODAL_CLOSED:
      return '';
    case ENTRIES_SELECT_ENTRY_ID:
      return action.externalID;
    default:
      return state;
  }
};

export const visibleEntry = (state = '', action) => {
  switch (action.type) {
    case MODAL_CLOSED:
      return '';
    case ENTRIES_SHOW_ENTRY:
      document.body.style.overflowY = 'hidden';
      return action.external_id;
    default:
      return state;
  }
};

const update = (state, mutations) => {
  return Object.assign({}, state, mutations);
};
const filterEntry = (nextState, external_id, type) => {
  if (nextState.categories.byID[type]) {
    nextState.categories.byID[type] = { ...nextState.categories.byID[type] };
    nextState.categories.byID[type].items = [...nextState.categories.byID[type].items];
    if (nextState.categories.byID[type].items.indexOf(external_id) === -1) {
      // only add if its a new external_id
      nextState.categories.byID[type].items.push(external_id);
      nextState.categories.byID[type].offset++;
    }
  } else {
    nextState.categories.byID[type] = {
      id: type,
      items: [],
      offset: 0,
      limit: DEFAULT_ENTRY_QUERY_LIMIT,
      total_count: DEFAULT_ENTRY_QUERY_LIMIT + 1,
      verified: false,
    };
    nextState.categories.byID[type].items.push(external_id);
    nextState.categories.allIds.push(type);
  }
};
const parseEntry = (nextState, entry, active_filterID) => {
  const external_id = entry.external_id;
  const media_type = entry.media_type;
  const animal_type = entry.animal_type;

  // filter into media categories
  if (nextState.items.allIds.indexOf(external_id) === -1) {
    // add entry to items if it doesn't exist
    nextState.items.allIds.push(external_id);
    nextState.items.byID[external_id] = entry;
  } else {
    /**
     * Im updating entry watch_count && isWinner because Im firing off a watch_count update whilst in bootstrap, and bootstrap may return old result for watch count
     */
    entry.watch_count = nextState.items.byID[external_id].watch_count > entry.watch_count ? nextState.items.byID[external_id].watch_count : entry.watch_count;
    entry.isWinner = entry.isWinner || nextState.items.byID[external_id].isWinner;
    nextState.items.byID[external_id] = { ...entry };
    nextState.items.byID[external_id].data = { ...entry.data };
  }
  // check media_type category exists. If not create it, else add to it
  filterEntry(nextState, external_id, media_type);
  // Also filter in animal categories
  filterEntry(nextState, external_id, animal_type);
};
const handleSingleEntry = (state, nextState, entry) => {
  parseEntry(nextState, entry);
  return nextState;
};
const handleMultipleEntries = (state, nextState, action) => {
  /*
   *  TODO : : Im not ordering entries anymore - as I was previously
   *  nextState.all_entries = uniqby(all_entries, 'external_id').sort((a, b) => (a.entered - b.entered) * -1);
   */
  const active_filterID = action.filter;
  nextState.base_url = action.base_url;
  nextState.isFetching = false;
  nextState.categories = { ...state.categories };
  nextState.categories.byID = { ...state.categories.byID };
  nextState.categories.allIds = [...state.categories.allIds];

  nextState.items = { ...state.items };
  nextState.items.byID = { ...state.items.byID };
  nextState.items.allIds = [...state.items.allIds];
  for (const entry of action.entries) {
    parseEntry(nextState, entry);
  }
  // Update active filter category totals
  if (nextState.categories.byID[active_filterID]) {
    nextState.categories.byID[active_filterID] = {
      ...nextState.categories.byID[active_filterID],
    };
  } else {
    nextState.categories.byID[active_filterID] = {
      id: active_filterID,
      items: [],
      offset: 0,
      limit: DEFAULT_ENTRY_QUERY_LIMIT,
      total_count: DEFAULT_ENTRY_QUERY_LIMIT + 1,
      verified: false,
    };
  }
  nextState.categories.byID[active_filterID].offset = parseInt(action.offset) + action.entries.length;
  nextState.categories.byID[active_filterID].total_count = action.total_count;
  nextState.categories.byID[active_filterID].verified = true;
  return nextState;
};
const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case RECEIVE_WINNERS_COMPLETE:
      nextState.winners = action.winners;
      return update(state, nextState);
    case BOOTSTRAP_COMPLETE:
      return update(state, handleMultipleEntries(state, nextState, action));
    case ENTRIES_REQUEST:
      nextState.isFetching = true;
      return update(state, nextState);
    case ENTRIES_RECEIVE:
      return update(state, handleMultipleEntries(state, nextState, action));
    case ENTRY_REQUEST:
      nextState.isFetching = true;
      return update(state, nextState);
    case ENTRY_RECEIVE:
      nextState.isFetching = false;
      nextState.categories = { ...state.categories };
      nextState.categories.byID = { ...state.categories.byID };
      nextState.categories.allIds = [...state.categories.allIds];
      nextState.items = { ...state.items };
      nextState.items.byID = { ...state.items.byID };
      nextState.items.allIds = [...state.items.allIds];
      return update(state, handleSingleEntry(state, nextState, action.entry));
    case ENTRIES_RATE_ENTRY:
      nextState.isFetching = true;
      return update(state, nextState);
    case ENTRIES_RATE_ENTRY_UPDATE: {
      let liked = Object.assign({}, state.liked);
      liked[action.externalID] = 1;
      nextState.liked = liked;
      return update(state, nextState);
    }
    case ENTRIES_RATE_ENTRY_COMPLETE: {
      nextState.items = { ...state.items };
      nextState.items.byID = { ...state.items.byID };
      const toUpdate = { ...state.items.byID[action.entry.external_id] };
      toUpdate.data = { ...state.items.byID[action.entry.external_id].data };
      toUpdate.rating_count = action.entry.rating_count;
      toUpdate.hasLiked = true;
      nextState.items.byID[action.entry.external_id] = toUpdate;
      nextState.isFetching = false;
      return update(state, nextState);
    }
    case ENTRIES_RATE_ENTRY_ERROR:
      nextState.isFetching = false;
      return update(state, nextState);
    case ENTRIES_VIEW_ENTRY_COMPLETE: {
      nextState.items = { ...state.items };
      nextState.items.byID = { ...state.items.byID };
      const toUpdate = { ...state.items.byID[action.entry.external_id] };
      toUpdate.data = { ...state.items.byID[action.entry.external_id].data };
      toUpdate.watch_count = action.entry.watch_count;
      nextState.items.byID[action.entry.external_id] = toUpdate;
      nextState.isFetching = false;
      return update(state, nextState);
    }
    case SUBMIT_ERROR:
    case SUBMIT_ERROR_API:
      nextState.isFetching = false;
      return update(state, nextState);
    default:
      return state;
  }
};
export default reducer;

/**
 * SELECTORS
 */

/** FILTER */
export const getFilterOptions = (state) => {
  return state.entries.filterOptions;
};

export const getSelectedFilter = (state) => {
  return state.selectedFilter;
};

const getFilterID = (state) => {
  return getSelectedFilter(state).value;
};

export const getIsMediaFilter = (state) => {
  const filterID = getFilterID(state);
  return state.entries.media_filters.indexOf(filterID) !== -1;
};

/** ENTRIES */
const getCategoryItemsByFilter = (state, filter) => {
  return state.entries.categories.byID[filter] && state.entries.categories.byID[filter].items ? state.entries.categories.byID[filter].items : [];
};

const getTotalCountByFilter = (state) => {
  const filter = getFilterID(state);
  return state.entries.categories.byID[filter] ? state.entries.categories.byID[filter].total_count : DEFAULT_ENTRY_QUERY_LIMIT + 1;
};

const getEntriesCountByFilter = (state) => {
  const filter = getFilterID(state);
  return filter === MEDIA_FILTER_ALL ? state.entries.items.allIds.length : getCategoryItemsByFilter(state, filter).length;
};

const getEntriesByFilter = (state) => {
  const filter = getFilterID(state);
  let refs = filter === MEDIA_FILTER_ALL ? state.entries.items.allIds : getCategoryItemsByFilter(state, filter);
  return refs.map((id) => {
    return state.entries.items.byID[id];
  });
};

export const getLimitByFilter = (state) => {
  const filter = getFilterID(state);
  const cat = state.entries.categories.byID[filter];
  return { offset: cat.offset, limit: cat.limit };
};

export const getSelectedEntryID = (state) => {
  return state.selectedEntryID;
};

export const getSelectedEntry = (state) => {
  const selectedEntryID = getSelectedEntryID(state);
  const filteredEntries = getEntriesByFilter(state);
  const index = filteredEntries.findIndex((o) => o.external_id === selectedEntryID);
  let prev = false,
    next = false;
  if (index >= 0) {
    if (index === 0) {
      // start
      prev = false;
      if (filteredEntries.length === 1) {
        // only on item in gallery
        next = false;
      } else {
        next = filteredEntries[index + 1].external_id;
      }
    } else if (index === filteredEntries.length - 1) {
      prev = filteredEntries[index - 1].external_id;
      next = false;
    } else {
      prev = filteredEntries[index - 1].external_id;
      next = filteredEntries[index + 1].external_id;
    }
    return { entry: filteredEntries[index], prev: prev, next: next };
  } else {
    return {
      entry: state.entries.items.byID[selectedEntryID],
      prev: prev,
      next: next,
    };
  }
};

export const getIsFetching = (state) => {
  return state.entries.isFetching;
};

export const getEntries = (state) => {
  return getEntriesByFilter(state);
};

export const getHasEntries = (state) => {
  return getEntriesCountByFilter(state) > 0;
};

export const getHasSelectedEntry = (state) => {
  const selectedEntryID = getSelectedEntryID(state);
  return state.entries.items.allIds.findIndex((id) => id === selectedEntryID) >= 0;
};

export const getMoreEntriesAvailable = (state) => {
  return getEntriesCountByFilter(state) < getTotalCountByFilter(state);
};

export const getBaseURL = (state) => {
  return state.entries.base_url;
};

export const getBorders = (state) => {
  return state.entries.borders;
};

export const getWinners = (state) => {
  return state.entries.winners;
};

export const getLiked = (state) => {
  return state.entries.liked;
};
