import { getDevice, USE_WEBP } from '../constants/AppConstants';

//stackoverflow.com/questions/5573096/detecting-webp-support
export const canUseWebP = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

export const convertFileExtensionToWebp = (url = '') => {
  if (USE_WEBP) {
    return url.replace(new RegExp('.(' + ['jpg', '.jpeg', 'png'].join('|') + ')$', 'i'), '.webp');
  } else {
    return url;
  }
};

export const processImages = (placeholder, images, widths = ['1x', '2x', '3x']) => {
  const { device, platform } = getDevice();
  const imgs = images.map((image) => {
    return convertFileExtensionToWebp(image);
  });
  const srcSet = imgs.reduce((acc, image, index) => {
    const width = widths[index] || '';
    acc += `${image} ${width}`;
    if (index < imgs.length - 1) {
      acc += ', ';
    }
    return acc;
  }, '');
  const placeholder_lazy = convertFileExtensionToWebp(placeholder);
  const src = device === 'desktop' ? imgs[2] : imgs[0];
  return { placeholder_lazy, srcSet, src };
};
