import { MODAL_CLOSE, MODAL_OPEN, MODAL_CLOSED } from '../../constants/AppConstants';

export const openModal = (opts) => {
  return {
    type: MODAL_OPEN,
    modalType: opts.modalType,
    modalProps: opts.modalProps,
  };
};

export const closeModal = () => {
  return {
    type: MODAL_CLOSE,
  };
};

export const closedModal = () => {
  return {
    type: MODAL_CLOSED,
  };
};
