import React, { Component } from 'react';
import { STATIC_PTH, SUBMIT_RESET } from '../../constants/AppConstants';
import { Link } from 'react-router-dom';

export default class AdditionialInfo extends Component {
  constructor(props) {
    super(props);

    this.handleNext = this.handleNext.bind(this);
  }
  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
  }

  handleNext() {
    this.props.handleNextStep({ currentStep: SUBMIT_RESET });
  }

  render() {
    const { uploadMode, copy } = this.props;
    const { txt_step4_title = '', txt_step4_description1 = '', txt_step4_description2 = '', txt_step4_description3 = '' } = copy;
    return (
      <div id="scrollTo" className="wrapper formTop additionial-info">
        <h2 className="title">{txt_step4_title}</h2>
        <p>{txt_step4_description1}</p>

        <p dangerouslySetInnerHTML={{ __html: txt_step4_description2 }}></p>

        <p>{txt_step4_description3}</p>

        <Link to={'/'} onClick={this.props.handleSubmitResetForm}>
          <div className="additionial-info__button">
            <div className="button">
              <span className="button_inner">Back</span>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
