import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ENTRY_IMAGE, ENTRY_TEXT, ENTRY_UNKNOWN, ENTRY_VIDEO, LOG, STATIC_PTH } from './../constants/AppConstants';
import EntryItemDetails from './../components/EntryItemDetails';
import { convertFileExtensionToWebp } from './../utils/image';

export default class EntryItem extends PureComponent {
  constructor(props) {
    super();
  }

  render() {
    const { data } = this.props || {};
    const { creation_category = '' } = data;
    const { rating_count, watch_count, external_id, handleLikeClick, isWinner, hasLiked } = this.props;
    const { entry_type, thumb_file, copy } = this.props;

    // LOG.enabled && console.log(`EntryItem`, { props: this.props, data });

    const { child_name, age, residence, pet_type, ribbon = '' } = data;
    let { txt_wall_ribbon = '' } = copy;

    const img = thumb_file ? convertFileExtensionToWebp(thumb_file) : '';
    let entryItemStyle = {
      backgroundImage: `url(${img})`,
    };

    let mediaType = 'photo';
    switch (entry_type) {
      case ENTRY_VIDEO:
        mediaType = 'video';
        break;
      case ENTRY_IMAGE:
        mediaType = 'photo';
        break;
      case ENTRY_TEXT:
        mediaType = 'story';
        entryItemStyle = {
          backgroundImage: `url(${STATIC_PTH}images/story_thumb.jpg)`,
        };
        break;
      default:
        console.warn('EntryItem:: entry_type unrecognised', external_id, entry_type);
        break;
    }

    let watermark = null;
    if (entry_type === ENTRY_UNKNOWN) {
      watermark = <span className="entryitem__watermark" />;
    }

    let zoomClass = '';
    if (data.showWatermark) {
      //zoomClass = ' entryitem__media--zoom';
    }

    let hasRibbon = false;
    if (!isWinner && ribbon !== '') {
      hasRibbon = true;
      txt_wall_ribbon = ribbon;
    }

    return (
      <div className="entrylist__item" data-external_id={external_id}>
        <div className="entryitem">
          {(isWinner || hasRibbon) && (
            <div className="ribbon ribbon-pink">
              <h2>{txt_wall_ribbon}</h2>
            </div>
          )}
          <Link className={'entryitem__media ' + creation_category + ' ' + 'entryitem__media--' + mediaType + zoomClass} style={entryItemStyle} to={'/creation/' + external_id}>
            {watermark}
          </Link>
          <EntryItemDetails
            child_name={child_name}
            residence={residence}
            age={age}
            pet_type={pet_type}
            external_id={external_id}
            rating_count={rating_count}
            watch_count={watch_count}
            hasLiked={hasLiked}
            handleLikeClick={handleLikeClick}
          />
        </div>
      </div>
    );
  }
}
