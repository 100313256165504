import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { STATIC_PTH, CDN_CACHEBUST } from '../constants/AppConstants';
import { setupIframeHeightPosting } from '../utils/iframe';

const HeaderSection = (props) => {
  useEffect(() => {
    setupIframeHeightPosting();
  }, []);

  return (
    <>
      <section className="featured_header featured_header--intro-text redraw-your-world">
        <div className="wrapper gradient-border">
          <div className=" clearfix redraw-your-world">
            <img className="featured_header__logo" src={`${STATIC_PTH}images/logo.jpg${CDN_CACHEBUST}`} alt="logo" />
            <h2>Thank you for your wonderful submissions!</h2>
            <h3>You showed us how you Redraw Your World in so many amazing different ways.</h3>
            <p>Check out the gallery below to get inspired by great entries from around Africa!</p>
            <br />
            <p className="submitform__label__description">Be you, your way, and keep Redrawing Your World!</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default HeaderSection;
