import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { closedModal } from './store/modal/modalActions';
import { getIsModalOpen, getIsModalClosing } from './store/modal/modalReducer';
import { bootstrap } from './store/app/appActions';

import Loader from './containers/Loader';
import Index from './containers/Index';
import Submit from './containers/Submit';
import ModalRoot from './containers/ModalRoot';
import Uploading from './containers/Uploading';
import HeaderSection from './components/HeaderSection';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.bootstrap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isModalOpen, isClosingModal } = this.props;
    if (!isModalOpen && isClosingModal) {
      this.props.closedModal();
    }
  }

  render() {
    return (
      <Router hashType="slash">
        <div className="outer pow">
          <HeaderSection />
          <Switch>
            <Route path="/index" component={Index} />
            <Redirect exact from="/" to="/index" />
            <Route path="/submit/:uploadMode/:currentStep" component={Submit} />
            <Redirect exact from="/submit/picture" to="/submit/picture/step1" />
            <Redirect exact from="/submit" to="/submit/picture/step1" />
            <Route path="/creation/:external_id" component={Index} />
            <Redirect to="/index" />
          </Switch>
          <ModalRoot />
          <Uploading />
          <Loader />
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    isModalOpen: getIsModalOpen(state),
    isClosingModal: getIsModalClosing(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    bootstrap: () => {
      dispatch(bootstrap());
    },
    closedModal: () => {
      dispatch(closedModal());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
