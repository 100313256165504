import { BOOTSTRAP_COMPLETE, configDefaults, EXTRA_FILTERS } from '../../constants/AppConstants';

const initState = {
  configData: configDefaults,
  base_url: null,
  appData: null,
  entry_preview_video: null,
  min_age: configDefaults.min_age, // minimum age for competition entry
  entryMode: '', // single, multiple
  entryLimit: 0,
  isReady: false,
  isLoading: false,
  favouriteShows: null,
};

const update = (state, mutations) => {
  return Object.assign({}, state, mutations);
};
const reducer = (state = initState, action) => {
  let nextState = {};
  switch (action.type) {
    case BOOTSTRAP_COMPLETE:
      nextState.base_url = action.base_url;
      nextState.appData = action.appData;
      nextState.entry_preview_video = action.entry_preview_video;
      nextState.favouriteShows = action.favShows;
      nextState.min_age = action.min_age;
      nextState.entryMode = action.entryMode;
      nextState.entryLimit = action.entryLimit;
      nextState.mediaTypes = action.mediaTypes;
      nextState.isReady = true;
      return update(state, nextState);
    default:
      return state;
  }
};
export default reducer;

export const getIsReady = (state) => {
  return state.app.isReady;
};

export const getMinimumAge = (state) => {
  return state.app.min_age;
};

export const getIsSingleEntry = (state) => {
  return state.app.entryMode === 'single';
};

export const getMediaTypes = (state) => {
  return state.app.mediaTypes;
};

export const getFavouriteShows = (state) => {
  return state.app.favouriteShows;
};

export const getFilterCategories = (state) => {
  return EXTRA_FILTERS;
};

export const getEntryLimit = (state) => {
  return state.app.entryLimit;
};

export const getEntryPreviewVideo = (state) => {
  return state.app.entry_preview_video;
};
