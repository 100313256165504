import request from 'superagent/lib/client';
import {
  APP_RATINGS_ID,
  APP_WATCH_ID,
  DEFAULT_ENTRY_QUERY_LIMIT,
  get_config_path,
  get_url_app_details,
  get_url_approved_entry_details,
  get_url_enter,
  get_url_entry_details,
  get_url_favourite_shows,
  get_url_rate_entry,
  get_url_winners,
  MEDIA_FILTER_ALL,
  MEDIA_FILTER_TEXT,
} from '../constants/AppConstants';

export const loadConfigService = (opts) => {
  const url = get_config_path();
  return new Promise((resolve, reject) => {
    request.get(url).end((err, response) => {
      if (err) {
        reject({
          show: true,
          title: 'Error',
          message: 'The Config data failed to load. Please try refreshing your browser and then contact xxx.xxx.',
          details: 'Config load error :: ' + url,
        });
      } else {
        resolve(JSON.parse(response.text));
      }
    });
  });
};

/**
 * Jollybase API
 *
 */
export const submitEntryService = (opts) => {
  const url = get_url_enter();
  // console.info('submitEntry', url, opts);
  return new Promise((resolve, reject) => {
    // resolve({base_url: 'base_url', external_id: 'spoofed'});
    // reject(JSON.stringify({statusCode: 200, text: {result: 'FAILURE', reason: 'ALREADY_ENTERED'}}));
    request
      .post(url)
      .type('form')
      .send(opts)
      .end((err, response) => {
        if (err) {
          reject(response);
        } else {
          const api_response = JSON.parse(response.text);
          if (api_response.result === 'SUCCESS') {
            resolve({
              base_url: api_response.base_url,
              external_id: api_response.entry.external_id,
            });
          } else {
            reject(response);
          }
        }
      });
  });
};

export const getAppDetailsService = () => {
  const url = get_url_app_details();
  // console.info('get_site_data', url);
  return new Promise((resolve, reject) => {
    request.post(url).end((err, response) => {
      if (err) {
        reject(response);
      } else {
        const api_response = JSON.parse(response.text);
        if (api_response.result === 'SUCCESS') {
          resolve({ base_url: api_response.base_url, app: api_response.app });
        } else {
          reject(response);
        }
      }
    });
  });
};

export const getFavouriteShowsService = () => {
  const url = get_url_favourite_shows();
  // console.info('get_favourite_shows', url);
  return new Promise((resolve, reject) => {
    request.post(url).end((err, response) => {
      if (err) {
        reject(response);
      } else {
        const api_response = JSON.parse(response.text);
        if (api_response.result === 'SUCCESS') {
          resolve(api_response.favourite_shows);
        } else {
          reject(response);
        }
      }
    });
  });
};

export const getWinnersService = () => {
  const url = get_url_winners();
  // console.info('get_winners', url);
  return new Promise((resolve, reject) => {
    request.post(url).end((err, response) => {
      if (err) {
        reject(response);
      } else {
        const api_response = JSON.parse(response.text);
        if (api_response.result === 'SUCCESS') {
          resolve(api_response.winners);
        } else if (api_response.reason === 'NO_ACTIVE_WINNERS') {
          resolve([]);
        } else {
          reject(response);
        }
      }
    });
  });
};

export const fetchApprovedEntryDetailsService = (offset = 0, limit = DEFAULT_ENTRY_QUERY_LIMIT, filter = MEDIA_FILTER_ALL, isMediaFilter = true) => {
  const url = get_url_approved_entry_details();
  // console.info('get_approved_entry_details | url', url, ' | offset', offset, ' | limit', limit, ' | filter', filter);

  let media_type = filter === MEDIA_FILTER_ALL ? '' : filter;
  if (filter === MEDIA_FILTER_TEXT) {
    media_type = 'none';
  }
  let params = { offset: offset, limit: limit };
  if (isMediaFilter) {
    params.media_type = media_type;
  } else {
    params.search = media_type;
  }

  return new Promise((resolve, reject) => {
    request
      .post(url)
      .type('form')
      .send(params)
      .end((err, response) => {
        if (err) {
          reject(err);
        } else {
          const api_response = JSON.parse(response.text);
          if (api_response.result === 'SUCCESS') {
            resolve(api_response);
            // resolve({base_url: api_response.base_url, entries: api_response.entries, totalEntries: api_response.total_count});
          } else {
            reject(response);
          }
        }
      });
  });
};

export const rateEntryService = (external_id, xsscookie) => {
  const url = get_url_rate_entry();
  // console.info('rate_entry | url', url, ' | external_id', external_id);
  return new Promise((resolve, reject) => {
    request
      .post(url)
      .type('form')
      .send({
        external_id: external_id,
        rating_id: APP_RATINGS_ID,
        _xss_cookie: xsscookie,
      })
      .end((err, response) => {
        if (err) {
          reject(response);
        } else {
          const api_response = JSON.parse(response.text);
          if (api_response.result === 'SUCCESS' || api_response.reason === 'ALREADY_VOTED') {
            resolve(api_response);
          } else {
            reject(api_response);
          }
        }
      });
  });
};

export const viewEntryService = (external_id) => {
  const url = get_url_rate_entry();
  // console.info('rate_entry | url', url, ' | external_id', external_id);
  return new Promise((resolve, reject) => {
    request
      .post(url)
      .type('form')
      .send({ external_id: external_id, rating_id: APP_WATCH_ID })
      .end((err, response) => {
        if (err) {
          reject(response);
        } else {
          const api_response = JSON.parse(response.text);
          if (api_response.result === 'SUCCESS' || api_response.reason === 'ALREADY_VOTED') {
            resolve(api_response);
          } else {
            reject(api_response);
          }
        }
      });
  });
};

export const getEntryDetailsService = (external_id) => {
  const url = get_url_entry_details();
  // console.info('get_entry_details | url', url, ' | external_id', external_id);
  return new Promise((resolve, reject) => {
    request
      .post(url)
      .type('form')
      .send({ external_id: external_id })
      .end((err, response) => {
        if (err) {
          reject(response);
        } else {
          const api_response = JSON.parse(response.text);
          if (api_response.result === 'SUCCESS') {
            // resolve(api_response.entry);
            resolve({
              base_url: api_response.base_url,
              entry: api_response.entry,
            });
          } else {
            reject(api_response);
          }
        }
      });
  });
};
