import React, { PureComponent } from 'react';
import { ENTRY_IMAGE, ENTRY_VIDEO, STATIC_PTH } from '../constants/AppConstants';
import { convertFileExtensionToWebp } from '../utils/image';

export default class ModalMediaItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.src !== this.props.src) {
      this.setLoaded(false);
    }
  }

  setLoaded(loaded) {
    this.setState({ loaded });
  }

  render() {
    const { loaded } = this.state;
    const { src, entry_type } = this.props;

    const spinner = `${STATIC_PTH}images/spinner.svg`;

    const isVideo = entry_type === ENTRY_VIDEO;
    const isImage = entry_type === ENTRY_IMAGE;
    // const isText = entry_type === ENTRY_TEXT;

    const img_src = isImage && src ? convertFileExtensionToWebp(src) : '';

    const classList = loaded ? (isImage ? 'fadeMedia__image-loaded' : 'fadeMedia__video-loaded') : 'fadeMedia';

    return (
      <div className="entry__media__padding">
        <div className="icon_container">
          <img className="icon icon_spin" src={spinner} />
        </div>

        {isVideo && <video src={src} className={classList} onLoadStart={() => this.setLoaded(true)} autoPlay playsInline muted controls loop />}
        {isImage && <img src={img_src} className={classList} onLoad={() => this.setLoaded(true)} />}
      </div>
    );
  }
}
