import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getIsReady } from './../store/app/appReducer';
import { batchFetchEntries, likeEntry, selectEntryID, selectFilter, showEntry } from './../store/entries/entriesActions';
import { getEntries, getFilterOptions, getMoreEntriesAvailable, getSelectedEntryID, getSelectedFilter } from './../store/entries/entriesReducer';
import { getAllCopy } from './../store/copy/copyReducer';
import Entries from './../components/Entries';
import FilterDrop from './../components/FilterDrop';
import { FILTERS_ACTIVE } from './../constants/AppConstants';
import { triggerEnsightenTrackingPageView } from './../constants/EnsightenTracking';

class Index extends Component {
  constructor(props) {
    super(props);
    this.handleMoreEntries = this.handleMoreEntries.bind(this);
    this.handleLikeClick = this.handleLikeClick.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);

    this.myRef = React.createRef();

    this.state = {
      hasBeenAnchored: false,
    };
  }

  componentDidMount() {
    const { params } = this.props.match;
    const { external_id } = params;
    if (external_id) {
      this.props.selectEntryID(external_id);
    }
    triggerEnsightenTrackingPageView({});
  }

  anchorUserToElement() {
    let scrollTarget = this.myRef.current;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const offset = -200;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + offset;
    window.scrollTo(0, top);

    this.setState({ hasBeenAnchored: true });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (this.myRef.current != null && !this.state.hasBeenAnchored) {
    //   this.anchorUserToElement();
    // }

    if (this.props.selectedEntryID !== prevProps.selectedEntryID) {
      this.showEntry(this.props.selectedEntryID);
    }

    // UNSAFE_componentWillReceiveProps moved here
    const { external_id = null } = this.props.match.params;
    const { external_id: prev_external_id = null } = prevProps.match.params;
    if (external_id !== prev_external_id) {
      this.props.selectEntryID(external_id);
    }
  }

  componentWillUnmount() {
    this.setState({ hasBeenAnchored: false });
  }

  render() {
    const { isReady, entries, moreEntriesAvailable, selectedFilter, filterOptions, copy } = this.props;
    const { txt_wall_title = '', btn_see_more = '', txt_drop_placeholder = '' } = copy || {};

    const buttonClass = moreEntriesAvailable ? 'button button--more' : 'button button--more disabled';
    const buttonClick = moreEntriesAvailable ? this.handleMoreEntries : null;

    if (!isReady) {
      return null;
    } else {
      return (
        <section>
          <div ref={this.myRef} className="wrapper b_purple wall">
            <div className="wall__header">
              <div className="wall__header-col">
                <h1>{txt_wall_title}</h1>
              </div>
              {FILTERS_ACTIVE && (
                <div className="wall__header-col">
                  <FilterDrop filterOptions={filterOptions} selectedFilter={selectedFilter} txt_drop_placeholder={txt_drop_placeholder} handleFilterSelect={this.handleFilterSelect} />
                </div>
              )}
            </div>
            <Entries copy={copy} entries={entries} handleLikeClick={this.handleLikeClick} />
            <div className="wall__controls">
              <a onClick={buttonClick} className={buttonClass}>
                <span className="button_inner ">{btn_see_more}</span>
              </a>
            </div>
          </div>
        </section>
      );
    }
  }

  showEntry() {
    this.props.showEntry();
  }

  handleMoreEntries(e) {
    e.preventDefault();
    this.props.batchFetchEntries();
  }

  handleLikeClick(e) {
    e.preventDefault();
    const external_id = e.currentTarget.getAttribute('data-external_id');
    this.props.likeEntry(external_id);
  }

  handleFilterSelect(filter) {
    this.props.selectFilter(filter);
  }
}

function mapStateToProps(state) {
  return {
    selectedEntryID: getSelectedEntryID(state),
    copy: getAllCopy(state),
    isReady: getIsReady(state),
    entries: getEntries(state),
    moreEntriesAvailable: getMoreEntriesAvailable(state),
    filterOptions: getFilterOptions(state),
    selectedFilter: getSelectedFilter(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    batchFetchEntries: () => {
      dispatch(batchFetchEntries());
    },
    selectEntryID: (id) => {
      if (id) {
        dispatch(selectEntryID(id));
      }
    },
    showEntry: () => {
      dispatch(showEntry());
    },
    likeEntry: (externalID) => {
      dispatch(likeEntry(externalID));
    },
    selectFilter: (filter) => {
      dispatch(selectFilter(filter));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
