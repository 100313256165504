/* global __WATCH__:false */

import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import reduxfreezeMiddleware from 'redux-freeze';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './rootReducer';
// import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction';

const configureStore = (initialState) => {
  let middlewares = [thunkMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    middlewares = [
      ...middlewares,
      createLogger({
        collapsed: true,
        duration: true,
        timestamp: true,
      }),
    ];
  }
  if (__WATCH__) {
    middlewares.unshift(reduxfreezeMiddleware);
  }

  /**
   * Removes composeWithDevTools as was getting this error in console:
   * You may not call store.getState() while the reducer is executing. The reducer has already received the state as an argument. Pass it down from the top reducer instead of reading it from the store.
   *
   * https://stackoverflow.com/questions/51503198/error-error-error-error-you-may-not-call-store-getstate-while-the-reducer
   */
  // const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
  const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        const nextRootReducer = require('./rootReducer').default;
        store.replaceReducer(nextRootReducer);
      });
    }
  }

  return store;
};
export default configureStore;
