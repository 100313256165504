// prettier-ignore
const KINDLE_DEVICES = /KFSUWI|KFAUWI|KFDOWI|KFGIWI|KFFOWI|KFMEWI|KFTBWI|KFARWI|KFASWI|KFSAWA|KFSAWI|KFAPWA|KFAPWI|KFAPWA|KFAPWI|KFSOWI|KFJWA|KFJWI|KFTT|KFOT|Kindle|Kindle Fire/i;

/**
 * Strategy
 * Do basic detection first
 * If returns unknown load MobileDetect (not platform) as module for more comprehensive detection
 */

export const getDevice = (platforms) => {
  const device = detectPlatform();
  // const device =  { type: 'unknown', os: 'unknown', family: 'unknown', version: 'unknown' };
  const { type, os, family, version } = device;
  let userPlatform = 'other';
  if (os === 'ios') {
    userPlatform = platforms.ios;
  } else if (os == 'fire') {
    userPlatform = platforms.amazon;
  } else if (os === 'android') {
    userPlatform = platforms.android;
  } else if (os === 'windows') {
    userPlatform = platforms.windowsPhone;
  } else if (os === 'blackberry') {
    userPlatform = platforms.blackberry;
  }

  if (type !== 'unknown') {
    return { ...device, userPlatform };
  }
  return device; // handle loading mobileDetect as module
};

export const getPlatform = () => {
  return detectPlatform();
};

function detectPlatform() {
  const ua = window.navigator.userAgent; // window.navigator.userAgent || window.navigator.vendor || window.opera;
  const device = {
    type: 'unknown',
    os: 'unknown',
    family: 'unknown',
    version: 'unknown',
  };

  const iphone = ua.match(/(iPhone\sOS)\s([\d_]+)/);
  const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
  if (iphone || ipad) {
    device.type = iphone ? 'smartphone' : 'tablet';
    device.os = 'ios';
    device.family = 'ios';
    device.version = iphone ? iphone[2].replace(/_/g, '.') : ipad[2].replace(/_/g, '.');
    return device;
  }

  const kindle = ua.match(KINDLE_DEVICES);
  if (kindle) {
    device.type = 'tablet';
    device.os = 'fire';
    device.family = 'android';
    return device;
  }

  const android = ua.match(/(Android)\s+([\d.]+)/);
  if (android) {
    device.type = detectTablet(ua);
    device.os = 'android';
    device.family = 'android';
    device.version = android[2];
    return device;
  }

  const webos = ua.match(/webOS|hpwOS/);
  if (webos) {
    device.type = detectTablet(ua);
    device.os = 'webos';
    device.family = 'webos';
    return device;
  }
  const blackberry = ua.match(/BlackBerry|\\bBB10\\b|rim[0-9]+|\\b(BBA100|BBB100|BBD100|BBE100|BBF100|STH100)\\b-[0-9]+/);
  if (blackberry) {
    device.type = detectTablet(ua);
    device.os = 'blackberry';
    device.family = 'blackberry';
    return device;
  }

  const windowsMobileOS = ua.match(/Windows CE.*(PPC|Smartphone|Mobile|[0-9]{3}x[0-9]{3})|Windows Mobile|Windows Phone [0-9.]+|WCE/);
  const WindowsPhoneOS = ua.match(/Windows Phone 10.0|Windows Phone 8.1|Windows Phone 8.0|Windows Phone OS|XBLWP7|ZuneWP7|Windows NT 6.[23]; ARM/);
  if (windowsMobileOS || WindowsPhoneOS) {
    device.type = 'smartphone';
    device.os = 'windows';
    device.family = 'windows';
    return device;
  }

  const windows = ua.match(/windows/i);
  const macintosh = ua.match(/macintosh/i);
  const cros = ua.match(/cros/i); // linux
  const desktop = windows || macintosh || cros;
  if (desktop) {
    device.type = 'desktop';
    device.os = windows ? 'windows' : macintosh ? 'macintosh' : cros ? 'linux' : 'unknown';
    device.family = windows ? 'windows' : macintosh ? 'macintosh' : cros ? 'linux' : 'unknown';
    return device;
  }

  return device;
}

function detectTablet(ua) {
  const isTablet = ua.match(/Tablet|Android.*Pixel C|PlayBook|Hudl HT7S3|Hudl 2/);
  return isTablet ? 'tablet' : 'smartphone';
}
