export const handleDOMReady = function (callback) {
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback());
  } else if (document.attachEvent) {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState != 'loading') {
        callback();
      }
    });
  }
};

const customActions = {
  ACTION_SUBMIT_PET: function () {
    window.location.hash = '/submit';
  },
  ACTION_VIEW_PET_GALLERY: function () {
    window.location.hash = '/index';
  },
};

export const addCustomActions = () => {
  const elements = document.querySelectorAll('[data-action]');
  for (const el of elements) {
    el.addEventListener('click', (e) => {
      const actionId = e?.currentTarget?.dataset?.action ?? false;
      if (actionId) {
        customActions[actionId]();
      }
    });
  }
};

export const addClosePopUpActions = () => {
  const elements = document.querySelectorAll('.js_close_popups');
  const popups = document.querySelectorAll('.popup');
  for (const el of elements) {
    el.addEventListener('click', (e) => {
      for (const popup of popups) {
        popup.classList.remove('active');
      }
    });
  }
};
