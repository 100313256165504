import storage from 'simplestorage.js';
import { canUseWebP } from './../utils/image';

// TODO for dev only - taken from https://www.boomerangtv.co.uk/animals/potw#/index.
// What is TurnerToons?
export const TurnerToons = {
  language: {
    code: 'en_GB',
    count: 1,
    linkedLanguages: [
      {
        language_code: 'en_GB',
        url: 'https://www.boomerangtv.co.uk/animals/potw',
      },
    ],
    linkedLookup: {
      en_GB: {
        language_code: 'en_GB',
        url: 'https://www.boomerangtv.co.uk/animals/potw',
      },
    },
  },
  platforms: {
    amazon: 'fire',
    android: 'android',
    windowsPhone: 'windows',
    ios: 'ios',
    blackberry: 'blackberry',
  },
  likesAndViews: {
    web_game: '1',
    quiz: '1',
    video: '1',
    comic: '1',
    animals_video: '1',
    animals_activity: '1',
    letscreate_video: '1',
    letscreate_activity: '1',
  },
  languageCode: 'en_GB',
  preview_device: '',
  staticURL: 'https://d2lv662meabn0u.cloudfront.net/boomerang/static/',
  dynamicURL: 'https://d2lv662meabn0u.cloudfront.net/boomerang/dynamic/',
  pageType: 'animals_potw',
  subPageType: 'null',
  gameSizes: {
    desktop: 0,
    tablet: 0,
    smartphone: 0,
  },
  baseURL: 'https://www.boomerangtv.co.uk/',
  onetrustURL: 'https://d2lv662meabn0u.cloudfront.net/boomerang/static/base/js/onetrust/performance-cookies-script.js',
  activeOnetrust: true,
  friendlyURLs: {
    comic: 'null',
    game: 'null',
    video: 'null',
    editorial: 'null',
  },
  pageContentData: {},
  FrontEndBaseURL: 'https://www.boomerangtv.co.uk/',
  FrontEndFriendlyURLs: {
    id: 1,
    games: 'games',
    games_all: 'games-all',
    videos: 'videos',
    videos_all: 'videos-all',
    campaigns: 'campaigns',
    created: 1457000929,
    modified: 1637329099,
    shows: 'shows',
    editorials: 'editorial',
    comics: 'comics',
    comics_all: 'comics-all',
    animals: 'animals',
    animals_potw: 'potw',
    animals_videos: 'videos',
    animals_activities: 'activities',
    animals_fun_facts: 'funfacts',
    letscreate: 'lets-create',
    letscreate_gallery: 'gallery',
    letscreate_videos: 'videos',
    letscreate_activities: 'activities',
    letscreate_cooking: 'cooking',
    letscreate_colouring: 'colouring',
    letscreate_craft: 'crafts',
    quizzes: 'quiz',
    quizzes_all: 'quiz-all',
    unlock: 'unlock',
    apps: 'apps',
    apps_all: 'aplikacje-all',
    characters: 'characters',
    data: [],
    game_category_pages: 'category',
  },
  FrontEndKnockKnockShowFriendlyUrl: '',
  Franchise: '',
  tracking: {},
  COMIC_DATA: {
    comic_base: 'null',
    comic_files: 'null',
    poster_url: 'null',
  },
  FOOTER_TRIGGERS: [
    {
      type: 'ot_script_page',
      trigger: 'privacy',
    },
    {
      type: 'text_page',
      trigger: 'terms',
    },
    {
      type: 'text_page',
      trigger: 'trademark',
    },
    {
      type: 'url',
      trigger: '',
    },
    {
      type: 'text_page',
      trigger: 'contact',
    },
    {
      type: 'text_page',
      trigger: 'legal',
    },
    {
      type: 'text_page',
      trigger: 'contact-us-apps',
    },
    {
      type: 'text_page',
      trigger: 'broadcaster',
    },
  ],
  COOKIE_POLICY: {
    oneTrustStarted: false,
    domLoaded: true,
    oneTrustActive: true,
    active: false,
    hide_button_txt: '',
    title_2: '',
    body_2: '',
    performanceCookiesEnabled: false,
    functionalCookiesEnabled: false,
    advertisingCookiesEnabled: false,
    oneTrustBannerExists: false,
  },
  cachebust: 1662385466,
  legacyCarouselActive: false,
  frontendURL: 'https://d2lv662meabn0u.cloudfront.net/boomerang/static/base',
  device: 'desktop',
  user_platform: 'other',
  isIE: false,
};

export const LOG = {
  enabled: false,
  enabledStore: true,
  clrBlack: 'background: black; color: white',
  clrGreen: 'background: green; color: white',
};
export const configDefaults = {
  locale: 'en_GB',
  api_url: 'https://turner-ugc.turner-apps.com/api/{action}/en_GB/apps/cn-africa-redraw/',
  static_pth: window.config.static_pth,
  dynamic_url: '//dev-boomerangtv-uk.jollydev.com/dynamic/',
  entry_page_count: 20,
  min_age: 0,
  filters_active: false,
  borders: [],
  language_texts: window.config.language_texts,
  device: (TurnerToons && TurnerToons.device) || null,
  webpEnabled: false, // set to true to switch on webp images
  competition: '',
  current_time: '',
};

export const BORDERS = configDefaults.borders;

export const WEBP_ENABLED = configDefaults.webpEnabled;
export const USE_WEBP = WEBP_ENABLED ? canUseWebP() : false;
let _DEVICE = null;
export const setDevice = ({ device = 'desktop', platform = 'desktop' }) => {
  _DEVICE = { device, platform };
  LOG.enabled && console.log(`%csetDevice`, LOG.clrBlack, _DEVICE);
};
export const getDevice = () => {
  return _DEVICE;
};

let _COOKIE_POLICY = null;
export const setCookiePolicy = ({ cookiePolicy, oneTrustActiveGroups }) => {
  const oneTrustActive = cookiePolicy.oneTrustActive || false;
  const otActiveGroups = oneTrustActiveGroups ? oneTrustActiveGroups.split(',') : [];
  _COOKIE_POLICY = {
    oneTrustActive,
    performanceCookiesEnabled: otActiveGroups.indexOf('pf') !== -1,
    functionalCookiesEnabled: otActiveGroups.indexOf('bb') !== -1,
    advertisingCookiesEnabled: otActiveGroups.indexOf('ad') !== -1,
  };
  LOG.enabled && console.log(`%csetCookiePolicy`, LOG.clrBlack, _COOKIE_POLICY);
};
export const getCookiePolicy = () => {
  return _COOKIE_POLICY;
};

export const IS_MOBILE_APP = configDefaults.isMobileApp;
export const LANGUAGE_TEXTS = configDefaults.language_texts;
export const APP_RATINGS_ID = '0';
export const APP_WATCH_ID = '1';
export const APP_LIKE_ID = 'petoftheweek_likes';
export const API_GET_APP_DETAILS = 'get_app_details';
export const API_CREATE_ENTRANT = 'create_entrant';
export const API_ENTER = 'enter';
export const API_GET_APPROVED_ENTRY_DETAILS = 'get_approved_entry_details';
export const API_GET_ENTRY_DETAILS = 'get_entry_details';
export const API_RATE = 'rate';
export const API_GET_FAVOURITE_SHOWS = 'get_favourite_shows';
export const API_GET_WINNERS = 'get_winners_details';
export const API_URL = configDefaults.api_url;
export const DYNAMIC_PTH = configDefaults.dynamic_url;
export const STATIC_PTH = configDefaults.static_pth;
export const CONFIG_URL = configDefaults.static_pth + 'assets/config.json';
export const COPY_URL = configDefaults.static_pth + 'assets/copy.xml';
export const DEFAULT_ENTRY_QUERY_LIMIT = configDefaults.entry_page_count;
export const FILTERS_ACTIVE = configDefaults.filters_active;
export const SUBMIT_VIDEO_URL = configDefaults.video ? DYNAMIC_PTH + configDefaults.video : null;

export const RECEIVE_WINNERS_START = 'bootstrap.RECEIVE_WINNERS_START';
export const RECEIVE_WINNERS_COMPLETE = 'bootstrap.RECEIVE_WINNERS_COMPLETE';
export const BOOTSTRAP_START = 'bootstrap.START';
export const BOOTSTRAP_COMPLETE = 'bootstrap.COMPLETE';

export const CDN_CACHEBUST = '?v=1';

/**
 * Comp Landing Page Data
 **/
const convertTimeDate = (time) => {
  const myDate = new Date(time);
  const offset = myDate.getTimezoneOffset() * 60 * 1000;

  const withOffset = myDate.getTime();

  return withOffset;
};

export const LANDING_PAGE = configDefaults?.competition ?? {};
export const LANDING_PAGE_DATA = LANDING_PAGE?.data ?? {};
const CURRENT_DATE_TIME = configDefaults?.current_time ?? 0;
const LANDING_START_DATE = LANDING_PAGE?.start_date ?? 0;
const LANDING_END_DATE = LANDING_PAGE?.end_date ?? 0;

export const landingPageActive = () => {
  const isActive = LANDING_PAGE?.active ?? false;
  const startDate = convertTimeDate(LANDING_START_DATE);
  const endDate = convertTimeDate(LANDING_END_DATE);
  const currentTime = convertTimeDate(CURRENT_DATE_TIME) / 1000;
  if (isActive) {
    if (startDate !== 0 && endDate !== 0 && currentTime !== 0) {
      let isActiveWithinDates = false;
      isActiveWithinDates = startDate <= currentTime;
      isActiveWithinDates = endDate >= currentTime;
      return isActiveWithinDates;
    } else {
      return false;
    }
  }

  return false;
};

/**
 * MODAL
 */
export const MODAL_OPEN = 'modal.OPEN';
export const MODAL_CLOSE = 'modal.CLOSE';
export const MODAL_CLOSED = 'modal.CLOSED';
export const MODAL_ERROR = 'MODAL_ERROR';
export const MODAL_ENTRY = 'MODAL_ENTRY';

/**
 * ENTRIES
 */
export const ENTRIES_REQUEST = 'entries.REQUEST';
export const ENTRIES_RECEIVE = 'entries.RECEIVE';
export const ENTRIES_SELECT_ENTRY_ID = 'entries.SELECT_ENTRY_ID';
export const ENTRIES_SHOW_ENTRY = 'entries.SHOW_ENTRY';
export const ENTRIES_RATE_ENTRY = 'entries.RATE_ENTRY';
export const ENTRIES_RATE_ENTRY_UPDATE = 'entries.RATE_ENTRY_UPDATE';
export const ENTRIES_RATE_ENTRY_COMPLETE = 'entries.RATE_ENTRY_COMPLETE';
export const ENTRIES_RATE_ENTRY_ERROR = 'entries.ENTRIES_RATE_ENTRY_ERROR';
export const ENTRIES_VIEW_ENTRY = 'entries.VIEW_ENTRY';
export const ENTRIES_VIEW_ENTRY_UPDATE = 'entries.VIEW_ENTRY_UPDATE';
export const ENTRIES_VIEW_ENTRY_COMPLETE = 'entries.VIEW_ENTRY_COMPLETE';
export const ENTRY_REQUEST = 'entry.REQUEST';
export const ENTRY_RECEIVE = 'entry.RECEIVE';

/**
 * ENTRY TYPES
 */
export const ENTRY_VIDEO = 'entry.VIDEO';
export const ENTRY_TEXT = 'entry.TEXT';
export const ENTRY_IMAGE = 'entry.IMAGE';
export const ENTRY_UNKNOWN = 'entry.UNKNOWN';

/**
 * FORM
 */
export const SUBMIT_STEP1 = 'STEP1';
export const SUBMIT_STEP2 = 'STEP2';
export const SUBMIT_STEP3 = 'STEP3';
export const SUBMIT_STEP4 = 'STEP4';
export const SUBMIT_STEP5 = 'STEP5';
export const SUBMIT_LANDING = 'LANDING';
export const SUBMIT_PICTURE = 'PICTURE';
export const SUBMIT_VIDEO = 'VIDEO';
export const SUBMIT_TEXT = 'TEXT';
export const SUBMIT_SET_UPLOAD_MODE = 'submit.SET_UPLOAD_MODE';
export const SUBMIT_SET_STEP = 'submit.SET_STEP';
export const SUBMIT_STEP_1 = 'submit.STEP1';
export const SUBMIT_STEP_2 = 'submit.STEP2';
export const SUBMIT_STEP_3 = 'submit.STEP3';
export const SUBMIT_STEP_4 = 'submit.STEP4';
export const SUBMIT_STEP_5 = 'submit.STEP5';
export const SUBMIT_REQUEST = 'submit.REQUEST';
export const SUBMIT_RECEIVE = 'submit.RECEIVE';
export const SUBMIT_RESET = 'submit.RESET';
export const SUBMIT_RESET_FORM = 'submit.RESET_FORM';
export const SUBMIT_ERROR = 'submit.ERROR';
export const SUBMIT_ERROR_API = 'submit.ERROR_API';

/**
 * FILTERS
 */
const types = configDefaults.language_texts?.animals_data?.potw_pet_types ?? [];
const filters = types.map((o, i) => {
  if (o.key == '-') {
    return { value: o.other_key.toLowerCase(), label: o.text };
  } else {
    return { value: o.key.toLowerCase(), label: o.text };
  }
});
/*
 * Remove duplicates from array
 */
const dataArray = filters.map((item) => {
  return [item.value, item];
});
const mapArray = new Map(dataArray);

export const EXTRA_FILTERS = [...mapArray.values()]; // de-duped
export const EXTRA_FILTER_VALUES = EXTRA_FILTERS.map((o) => {
  return o.value;
});

export const MEDIA_FILTER_SELECT = 'media.filter.select';
export const MEDIA_FILTER_ALL = 'all';
export const MEDIA_FILTER_TEXT = 'text';

export const getLikedCookie = () => {
  let cookie = {};
  if (storage.canUse()) {
    cookie = storage.get(APP_LIKE_ID) || {};
  }
  return cookie;
};

export const setLikedCookie = (id) => {
  let cookie = {};
  if (storage.canUse() && window.performanceCookiesAccepted === true) {
    cookie = storage.get(APP_LIKE_ID) || {};
    let newcookie = { ...cookie };
    newcookie[id] = 1;
    storage.set(APP_LIKE_ID, newcookie);
  }
};

export const get_config_path = () => {
  return CONFIG_URL;
};

export const get_copy_path = () => {
  return COPY_URL;
};

export const get_url_app_details = () => {
  const action = API_GET_APP_DETAILS;
  return API_URL.replace('{action}', action);
};

export const get_url_favourite_shows = () => {
  const action = API_GET_FAVOURITE_SHOWS;
  return API_URL.replace('api', 'api_animals').replace('{action}', action);
};

export const get_url_winners = () => {
  const action = API_GET_WINNERS;
  return API_URL.replace('api', 'api_animals').replace('{action}', action);
};

export const get_url_enter = () => {
  const action = API_ENTER;
  return API_URL.replace('{action}', action);
};

export const get_url_create_entrant = () => {
  const action = API_CREATE_ENTRANT;
  return API_URL.replace('{action}', action);
};

export const get_url_approved_entry_details = () => {
  const action = API_GET_APPROVED_ENTRY_DETAILS;
  return API_URL.replace('{action}', action);
};

export const get_url_rate_entry = () => {
  const action = API_RATE;
  return API_URL.replace('{action}', action);
};

export const get_url_entry_details = () => {
  const action = API_GET_ENTRY_DETAILS;
  return API_URL.replace('{action}', action);
};

export const kFormatter = (num) => {
  if (isNaN(num)) {
    num = parseInt(num);
  }
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
};
