import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import { EXTRA_FILTERS, LOG } from '../../constants/AppConstants';
import { setupIframeHeightPosting } from '../../utils/iframe';
const maxLength = 132;

export default class PersonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // media_title: '',
      // tell_us_more: '',
      first_name: '',
      full_name: '',
      email: '',
      age: '',
      petTypes: [],
      // favourite_kind_of_creation: '',
      //
      childs_creation: '',
      residence: '',
      about_creation: '',
      charCount: maxLength,
    };
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.showTandCPopUp = this.showTandCPopUp.bind(this);
    this.showPrivacyPopUp = this.showPrivacyPopUp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let scrollTarget = document.querySelectorAll('.formtop')[0];
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;

    setupIframeHeightPosting();

    window.scrollTo(0, top);
    if (this.state.petTypes.length === 0) {
      this.setState({ petTypes: EXTRA_FILTERS });
    }
  }

  updateCharCount(target) {
    this.setState({ charCount: maxLength - target.value.length });
  }

  componentWillUnmount() {}

  render() {
    const { minimumAge, favouriteShows, copy, uploadMode, fileIsGif } = this.props;
    const {
      txt_step1_pic_tandcs = '',
      txt_step1_story_tandcs = '',
      txt_step1_vid_tandcs = '',
      txt_step2_title = '',
      txt_step2_label_title = '',
      txt_step2_label_title_description = '',
      txt_step2_label_pet_story_description = '',
      txt_step2_label_name = '',
      txt_step2_label_name_description = '',
      txt_step2_label_age = '',
      txt_step2_label_age_description = '',
      txt_step2_label_guardian = '',
      txt_step2_label_guardian_description = '',
      txt_step2_label_email = '',
      txt_step2_label_email_description = '',
      txt_step2_label_show = '',
      btn_form_submit = '',
      btn_form_back = '',
      txt_step2_dropdown_placeholder = '',
      txt_step = '',
      txt_of = '',
      txt_form_picture = '',
      txt_form_video = '',
      txt_form_story = '',
      competition_title = '',
      privacy_policy_title = '',
      txt_disclaimer_txt = '',
      txt_step2_description = '',
      txt_step2_tell_us_more = '',
      letsc_txt_step2_label_title = '',
      txt_step2_favourite = '',
      txt_step2_privacy_policy = '',
      txt_step2_tandc = '',
      privacy_policy_link_text = '',
      competition_link_text = '',
      //
      txt_step2_label_childs_creation = '',
      txt_step2_label_residence = '',
      txt_step2_label_pet_age = '',
      txt_step2_label_pet_food = '',
      txt_step2_label_about_creation = '',
    } = copy || {};

    // let labelTitle = txt_step2_label_title;
    // let stepCount = '2';
    // let termsCheckCopy = competition_title;
    // switch (uploadMode) {
    //   case SUBMIT_PICTURE:
    //     labelTitle = labelTitle.replace('{media_type}', txt_form_picture);
    //     stepCount = fileIsGif ? '2' : '3';
    //     termsCheckCopy = txt_step1_pic_tandcs;
    //     break;
    //   case SUBMIT_VIDEO:
    //     labelTitle = labelTitle.replace('{media_type}', txt_form_video);
    //     termsCheckCopy = txt_step1_vid_tandcs;
    //     break;
    //   case SUBMIT_TEXT:
    //     labelTitle = labelTitle.replace('{media_type}', txt_form_story);
    //     termsCheckCopy = txt_step1_story_tandcs;
    //     break;
    // }

    const { residence } = this.state;
    return (
      <section>
        <div className="wrapper b_purple wall">
          <div className="formtop letsCreateForm">
            <h2>{txt_step2_title}</h2>
            <p className="description">{txt_step2_description}</p>

            {/* OUR PETS NAME IS : childs_creation */}
            <div className="submitform__row">
              <label className="submitform__label" htmlFor="childs_creation" id="childs_creationLabel">
                {'What is the title of your child’s creation?'}
              </label>
              <p className="submitform__label__description">{txt_step2_label_title_description}</p>
              <input
                className="submitform__input"
                onChange={this.handleInputChange}
                id="childs_creation"
                type="text"
                name="childs_creation"
                ref="childs_creation"
                maxLength="42"
                required
                pattern="([A-z0-9À-ž\s.'!?&-]){2,}"
                value={this.state.childs_creation || ''}
              />
              <p className="submitform__error" id="childs_creationError" />
            </div>

            {/* OUR PETS AGE
            <div className="submitform__row">
              <label className="submitform__label" htmlFor="pet_age" id="pet_ageLabel">
                {txt_step2_label_pet_age}
              </label>
              <input
                className="submitform__input  submitform__input--age"
                onChange={this.handleInputChange}
                id="pet_age"
                type="number"
                name="pet_age"
                ref="pet_age"
                min="0"
                max="50"
                step="1"
                required
                pattern="[0-9]{1,3}"
                value={this.state.pet_age || ''}
              />
              <p className="submitform__error" id="pet_ageError" />
            </div>*/}

            {/* OUR PETS FAVOURITE FOOD
            <div className="submitform__row">
              <label className="submitform__label" htmlFor="pet_food" id="pet_foodLabel">
                {txt_step2_label_pet_food}
              </label>
              <input
                className="submitform__input"
                onChange={this.handleInputChange}
                id="pet_food"
                type="text"
                name="pet_food"
                ref="pet_food"
                maxLength="42"
                required
                pattern="([A-z0-9À-ž\s.'!?&-]){2,}"
                value={this.state.pet_food || ''}
              />
              <p className="submitform__error" id="pet_foodError" />
            </div>
            */}

            {/* A STORY ABOUT OUR PET */}
            <div className="submitform__row">
              <label className="submitform__label" htmlFor="about_creation" id="about_creationLabel">
                {'Tell us more about the creation:'}
              </label>
              <p className="submitform__label__description">{txt_step2_label_pet_story_description}</p>
              <textarea
                className="submitform__input"
                onChange={this.handleInputChange}
                id="about_creation"
                type="text"
                name="about_creation"
                ref="about_creation"
                required
                rows="4"
                cols="50"
                pattern="([A-z0-9À-ž\s.'!?&-]){2,}"
                value={this.state.about_creation || ''}
                maxLength="132"
              />
              <p className="submitform__input__char-count">
                {this.state.charCount}/{maxLength} characters remaining
              </p>
              <p className="submitform__error" id="about_creationError" />
            </div>

            <div className="submitform__row">
              <label className="submitform__label" htmlFor="first_name" id="first_nameLabel">
                {txt_step2_label_name}
              </label>
              <p className="submitform__label__description">{txt_step2_label_name_description}</p>
              <input
                className="submitform__input"
                onChange={this.handleInputChange}
                id="first_name"
                type="text"
                name="first_name"
                ref="first_name"
                maxLength="42"
                required
                pattern="([A-z0-9À-ž\s.'!?&-]){2,}"
                value={this.state.first_name || ''}
              />
              <p className="submitform__error" id="first_nameError" />
            </div>

            <div className="submitform__row">
              <label className="submitform__label" htmlFor="age" id="ageLabel">
                {txt_step2_label_age}
              </label>
              <p className="submitform__label__description">{txt_step2_label_age_description}</p>
              <input
                className="submitform__input  submitform__input--age"
                onChange={this.handleInputChange}
                id="age"
                type="number"
                name="age"
                ref="age"
                min={minimumAge}
                max="100"
                step="1"
                required
                pattern="[0-9]{1,3}"
                value={this.state.age || ''}
              />
              <p className="submitform__error" id="ageError" />
            </div>

            {/* OUR PET IS A : residence */}
            <div className="submitform__row">
              <label className="submitform__label" htmlFor="residence" id="residenceLabel">
                {'Select your country of residence'}
              </label>
              <p className="submitform__label__description">{'(Will be shown with the creation if selected)'}</p>
              <Dropdown
                id="residence"
                name="residence"
                options={this.state.petTypes}
                onChange={this.handleOnSelect}
                value={residence}
                placeholder={txt_step2_dropdown_placeholder}
                className="fullwidth"
              />
              <p className="submitform__error" id="residenceError" />
            </div>

            <div className="submitform__row">
              <label className="submitform__label" htmlFor="full_name" id="full_nameLabel">
                {txt_step2_label_guardian}
              </label>
              <p className="submitform__label__description">{txt_step2_label_guardian_description}</p>
              <input
                className="submitform__input"
                onChange={this.handleInputChange}
                id="full_name"
                type="text"
                name="full_name"
                ref="full_name"
                maxLength="42"
                required
                pattern="([A-z0-9À-ž\s.!?&'-]){2,}"
                value={this.state.full_name || ''}
              />
              <p className="submitform__error" id="full_nameError" />
            </div>

            <div className="submitform__row">
              <label className="submitform__label" htmlFor="email" id="emailLabel">
                {txt_step2_label_email}
              </label>
              <p className="submitform__label__description">{txt_step2_label_email_description}</p>
              <input className="submitform__input" onChange={this.handleInputChange} id="email" type="email" name="email" ref="email" required value={this.state.email || ''} />
              <p className="submitform__error" id="emailError" />
            </div>

            <div className="submitform__checkbox-container">
              <div className="submitform__row submitform__row--checkbox">
                <input onChange={this.handleInputChange} id="privacy_policy" type="checkbox" name="privacy_policy" required ref="privacy_policy" />
                <label className="submitform__checkbox" htmlFor="privacy_policy" id="privacy_policyLabel" />
                <span className="submitform__termslabel" onClick={this.showPrivacyPopUp}>
                  {privacy_policy_title}
                </span>
                <p className="submitform__error" id="privacy_policyError" />
              </div>

              <div className="submitform__row submitform__row--checkbox">
                <input onChange={this.handleInputChange} id="tandcs" type="checkbox" name="tandcs" required ref="tandcs" />
                <label className="submitform__checkbox" htmlFor="tandcs" id="tandcsLabel" />
                <span className="submitform__termslabel" onClick={this.showTandCPopUp}>
                  {competition_title}
                </span>
                <p className="submitform__error" id="tandcsError" />
              </div>

              <div className="submitform__row submitform__row--checkbox">
                <input onChange={this.handleInputChange} id="emailpermission" type="checkbox" name="emailpermission" ref="emailpermission" />
                <label className="submitform__checkbox" htmlFor="emailpermission" id="emailpermissionLabel" />
                <span className="submitform__termslabel">
                  {
                    "I hereby give permission for Cartoon Network to contact me via my email address provided in this form should they want to know more about my child's creation or for post-competition marketing."
                  }
                </span>
                <p className="submitform__error" id="emailpermissionError" />
              </div>
            </div>

            <div className="submitform__row-buttons">
              <div className="button-group">
                <div className="submitform__col-button">
                  <div className="button" onClick={this.showPrivacyPopUp}>
                    <span className="button_inner">{privacy_policy_link_text}</span>
                  </div>
                </div>

                <div className="submitform__col-button">
                  <div className="button" onClick={this.showTandCPopUp}>
                    <span className="button_inner">{competition_link_text}</span>
                  </div>
                </div>
              </div>

              <div className="submitform__col-button">
                <p className="presubmit_text">
                  You will receive an email to validate this entry after you&#x27;ve submitted it - you will have 48h to do so, so don&#x27;t forget to check your inbox!
                </p>
                <a onClick={this.handleSubmit} className="button">
                  <span className="button_inner">{btn_form_submit}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  handleOnSelect(obj) {
    this.setState({
      residence: obj.value,
    });
    const error = document.getElementById(`residenceError`);
    error.classList.remove('active');
    error.textContent = '';
  }

  showTandCPopUp(e) {
    e.preventDefault();
    let el = document.getElementById('terms_popup_content_potw');
    el.addEventListener('click', () => {
      if (el.classList.value.includes('active')) {
        el.classList.remove('active');
      }
    });
    el.className += ' active';
    // window.scrollTo(0, 0);
  }

  showPrivacyPopUp(e) {
    e.preventDefault();
    let el = document.getElementById('privacy_popup_content_potw');
    el.addEventListener('click', () => {
      if (el.classList.value.includes('active')) {
        el.classList.remove('active');
      }
    });
    el.className += ' active';
    // window.scrollTo(0, 0);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    name === 'about_creation' && this.updateCharCount(target);

    this.setState({
      [name]: value,
    });
    this.showInputError(event.target.name, value);
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.showFormErrors()) {
      console.warn('Form incomplete');
      return;
    }
    const { petTypes, residence } = this.state;
    const index = petTypes.findIndex((o) => o.value === residence);
    const residence_value = petTypes[index].label;

    // const { childs_creation, pet_age, pet_food, about_creation } = this.state;
    const { first_name, full_name, email, age, childs_creation, about_creation, emailpermission = false } = this.state;

    const data = { first_name, full_name, email, age, childs_creation, residence, about_creation, emailpermission };

    console.log(data);
    this.props.handleSubmitEntry(data);
  }

  showFormErrors() {
    const inputs = document.querySelectorAll('.letsCreateForm input');
    const textAreas = document.querySelectorAll('.letsCreateForm textArea');
    let isFormValid = true;

    for (let input of inputs) {
      input.classList.add('active');
      const isInputValid = this.showInputError(input.name, input.value);
      LOG.enabled && console.log(`input ${input.name} : ${isInputValid}`);
      if (!isInputValid) {
        isFormValid = false;
      }
    }
    for (let textArea of textAreas) {
      textArea.classList.add('active');
      const isInputValid = this.showInputError(textArea.name);
      LOG.enabled && console.log(`input ${textArea.name} : ${isInputValid}`);
      if (!isInputValid) {
        isFormValid = false;
      }
    }
    const isInputValid = this.showInputError('residence');
    LOG.enabled && console.log(`input residence : ${isInputValid}`);
    if (!isInputValid) {
      isFormValid = false;
    }
    return isFormValid;
  }

  /**
   * http://moduscreate.com/reactjs-form-validation-approaches/
   * https://codepen.io/jmalfatto/pen/YGjmaJ?editors=0110
   */
  showInputError(refName, value) {
    if (refName === 'email') {
      let label = document.getElementById(`${refName}Label`).textContent;
      const error = document.getElementById(`emailError`);
      const emailRegexCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      if (emailRegexCheck) {
        error.classList.remove('active');
        error.textContent = '';
        return true;
      } else {
        error.textContent = `${label} ` + this.props.copy.validation_email_mismatch;
        error.classList.add('active');
        return false;
      }
    } else if (refName === 'residence') {
      const error = document.getElementById(`residenceError`);
      if (this.state.residence) {
        error.classList.remove('active');
        error.textContent = '';
        return true;
      } else {
        error.textContent = this.props.copy.validation_step2_dropdown;
        error.classList.add('active');
        return false;
      }
    } else if (refName !== '') {
      const validity = this.refs[refName].validity;
      let label = document.getElementById(`${refName}Label`).textContent;
      const error = document.getElementById(`${refName}Error`);
      const isPrivacy = refName === 'privacy_policy';
      const isTandCs = refName === 'tandcs';

      const isAge = refName === 'age';
      const isPetAge = refName === 'pet_age';

      label = label.replace(' IS', '');
      if (!validity.valid) {
        if (validity.valueMissing && isTandCs) {
          error.textContent = this.props.copy.validation_tandcs;
        } else if (validity.valueMissing && isPrivacy) {
          error.textContent = this.props.copy.validation_tandcs;
        } else if (validity.valueMissing) {
          error.textContent = `${label} ` + this.props.copy.validation_required;
          // eslint-disable-next-line no-dupe-else-if
        } else if (validity.valueMissing && isTandCs) {
          document.getElementById(`${refName}Label`).classList.add('error');
        } else if (validity.rangeUnderflow && (isAge || isPetAge)) {
          error.textContent = `${label} ` + this.props.copy.validation_to_low;
        } else if (validity.rangeOverflow && (isAge || isPetAge)) {
          error.textContent = `${label} ` + this.props.copy.validation_to_high;
        } else if (validity.typeMismatch) {
          error.textContent = `${label} ` + this.props.copy.validation_email_mismatch;
        } else if (validity.patternMismatch) {
          error.textContent = `${label} ` + this.props.copy.validation_pattern_mismatch;
        }
        error.classList.add('active');
        return false;
      } else {
        error.classList.remove('active');
      }
      if (error) {
        error.textContent = '';
      }
      return true;
    } else {
      console.warn('refName empty');
      return true;
    }
  }
}
