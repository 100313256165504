import React, { Component } from 'react';
import { connect } from 'react-redux';
import { STATIC_PTH } from './../constants/AppConstants';
import { getAllCopy } from './../store/copy/copyReducer';
import { getIsUploading } from './../store/submit/submitReducer';

class Loader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { copy } = this.props;
    const { txt_uploading = '' } = copy || {};
    const isVisible = this.props.isUploading || false;
    const uploading = `${STATIC_PTH}images/uploading.svg`;

    return (
      <div>
        {isVisible && (
          <div className="modal__container">
            <div className="modal__foreground modal__foreground--entry">
              <div className="full_wrapper">
                <div className="modal__panel">
                  <div className="modal__content modal__content--uploading">
                    <div className="uploading">
                      <div className="uploading__details">
                        <h1>{txt_uploading}</h1>
                        <span className="uploading__animation">
                          <img className="uploading" src={uploading} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal__background" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUploading: getIsUploading(state),
    copy: getAllCopy(state),
  };
}

export default connect(mapStateToProps)(Loader);
