import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalError from './../components/ModalError';
import ModalEntry from './../components/ModalEntry';

import { closeModal } from './../store/modal/modalActions';
import { getModal } from './../store/modal/modalReducer';
import { getAllCopy } from './../store/copy/copyReducer';
import { getSelectedEntry } from './../store/entries/entriesReducer';
import { likeEntry } from './../store/entries/entriesActions';

import { MODAL_ENTRY, MODAL_ERROR } from './../constants/AppConstants';

class ModalRoot extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleLikeClick = this.handleLikeClick.bind(this);
  }

  render() {
    const { copy, entry, prev, next } = this.props;
    const { modalType, modalProps } = this.props.modal;
    if (!modalType) {
      return null;
    }

    switch (modalType) {
      case MODAL_ENTRY:
        return <ModalEntry copy={copy} {...entry} prev={prev} next={next} handleCloseModal={this.handleCloseModal} handleLikeClick={this.handleLikeClick} />;
      case MODAL_ERROR:
        return <ModalError copy={copy} {...modalProps} handleCloseModal={this.handleCloseModal} />;
    }
  }

  handleCloseModal(e) {
    // e.preventDefault();
    this.props.closeModal();
  }

  handleLikeClick(e) {
    e.preventDefault();
    const external_id = e.currentTarget.getAttribute('data-external_id');
    this.props.likeEntry(external_id);
  }
}

function mapStateToProps(state) {
  const { entry, prev, next } = getSelectedEntry(state);
  return {
    modal: getModal(state),
    copy: getAllCopy(state),
    entry,
    prev,
    next,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
    likeEntry: (externalID) => {
      dispatch(likeEntry(externalID));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
