import { getDevice, IS_MOBILE_APP, LOG } from '../constants/AppConstants';

export const ENSIGHTEN_NEW = typeof window.ensightenTracking != 'undefined' && window.ensightenTracking.type == 'new' ? true : false;

const getScreenOrientation = (device) => {
  let orientation = 'desktop';
  if (device !== 'desktop') {
    if (window.orientation == 0 || window.orientation == 180) {
      orientation = 'mobile|potrait';
    } else {
      orientation = 'mobile|landscape';
    }
  }
  return orientation;
};

const getPresentationTemplate = (device) => {
  let pt = 'nvs';
  pt = device === 'smartphone' ? 'mobile web' : device;
  return pt;
};

const getDomain = () => {
  const hostname = window.location.hostname.replace('www.', '');
  const pathname = window.location.pathname;
  const domain = hostname + pathname;
  return domain.toLowerCase();
};

const getDataLayer = () => {
  const { device, platform } = getDevice();
  const { feedcode, previouspage, screenorientation, ...baseData } = window.trackingVariables;
  baseData.contenttitle = baseData.contenttitle ? baseData.contenttitle : document.title.toLowerCase();
  baseData.screenorientation = getScreenOrientation(device);
  baseData.presentationtemplate = getPresentationTemplate(device);
  if (IS_MOBILE_APP) {
    baseData.domain = `${baseData.domain.replace('web', 'app')}|${platform}`;
    baseData.platform = 'app';
  }
  return baseData;
};

const ENTRY_SUBMIT_DATA = {
  sectionlevel3: 'step 4', // step 1 - step 5 or gallery
  interaction: 'lets create submission click',
  interactionevent: '1',
};

const THUMBNAIL_CLICK_DATA = {
  sectionlevel3: 'lets create', // step 1 - step 5 or gallery
  contenttype: 'section front',
  interaction: 'lets create thumbnail cta click',
  interactionevent: '1',
};

const PAGE_VIEW_DATA = {
  section: 'lets create',
  subsection: 'lets create|gallery',
  sectionlevel3: 'gallery', // step 1 - step 5 or gallery
  contenttype: 'contest',
  pageviewevent: '1',
};

export const triggerEnsightenTrackingEntrySubmit = function (props) {
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const domain = getDomain();
    let pageData = { ...dataLayer, ...ENTRY_SUBMIT_DATA };
    pageData.pageName = `${domain}step4`;
    pageData.contenttype = 'contest';
    LOG.enabled && console.log('%ctriggerEnsightenTrackingEntrySubmit', LOG.clrBlack, pageData);
    turner_metadata.trackAction.push({ type: 'interaction', data: pageData });
  }
};

export const triggerEnsightenTrackingThumbnailClick = function (props) {
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const domain = getDomain();
    let pageData = { ...dataLayer, ...THUMBNAIL_CLICK_DATA };
    pageData.pageName = domain;
    LOG.enabled && console.log('%ctriggerEnsightenTrackingThumbnailClick', LOG.clrBlack, pageData);
    turner_metadata.trackAction.push({ type: 'interaction', data: pageData });
  }
};

export const triggerEnsightenTrackingPageView = function ({ stepId }) {
  const stepNum = stepId ? stepId.replace('STEP', '') : false;
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const domain = getDomain();
    const pageName = stepNum ? `${domain}step${stepNum}` : `${domain}`;
    let pageData = { ...dataLayer, ...PAGE_VIEW_DATA };
    pageData.pageName = pageName;
    pageData.sectionlevel3 = stepNum ? `step ${stepNum}` : 'gallery';
    LOG.enabled && console.log('%ctriggerEnsightenTrackingStepView', LOG.clrBlack, pageData);
    turner_metadata.trackAction.push({ type: 'pageview', data: pageData });
  }
};
