import React, { Component } from 'react';

export default class EntryStep5 extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let scrollTarget = document.getElementById('scrollTo');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const floatyHeader = -138;
    const top = scrollTarget.getBoundingClientRect().top + scrollTop + floatyHeader;
    window.scrollTo(0, top);
  }

  render() {
    const { copy = {}, currentStep, uploadMode, minimumAge, mediaTypes, favouriteShows, cachedData } = this.props;
    const { txt_step5_title = '', txt_step5_description = '' } = copy;
    return (
      <div id="scrollTo" className="wrapper additionial-info">
        <h2 className="title">{txt_step5_title}</h2>
        <p>{txt_step5_description}</p>
      </div>
    );
  }
}
