import React, { Component } from 'react';
import Dropdown from 'react-dropdown';

export default class FilterDrop extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { filterOptions, selectedFilter, handleFilterSelect, txt_drop_placeholder } = this.props;
    return <Dropdown options={filterOptions} onChange={handleFilterSelect} value={selectedFilter} placeholder={txt_drop_placeholder} />;
  }
}
