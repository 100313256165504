import React from 'react';

const ConsentFormListItem = ({ text }) => {
  return text && text !== '' ? (
    <li>
      <span>{text}</span>
    </li>
  ) : null;
};
export default ConsentFormListItem;
