import { LANGUAGE_TEXTS } from '../../constants/AppConstants';

const all_language_texts = LANGUAGE_TEXTS || {};
/**
 * filter out non letsc_ copy
 */
const initState = Object.keys(all_language_texts)
  .filter((key) => key.indexOf('potw_') !== -1)
  .reduce((obj, key) => {
    const adj_key = key.replace('potw_', '');
    obj[adj_key] = all_language_texts[key];
    return obj;
  }, {});

const reducer = (state = initState, action) => {
  // switch (action.type) {
  //     case SET_ACTIVE_CLIENT:
  //         state.activeClient        = action.payload;
  //         break;
  //     default:
  //         return state;
  // }
  return state;
};

export default reducer;

/**
 * SELECTORS
 */
export const getAllCopy = (state) => {
  return state.copy;
};
